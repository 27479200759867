"use client";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Popular1 from "../../assets/images/popular-1.jpg";
import Popular2 from "../../assets/images/popular-2.jpg";
import Popular3 from "../../assets/images/popular-3.jpg";
import CardList from "../../components/cardList";

const cards = [
  {
    imageSrc: Popular1,
    altText: "San miguel, italy",
    subtitle: "Italy",
    title: "San miguel",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: Popular2,
    altText: "Burj khalifa, dubai",
    subtitle: "Dubai",
    title: "Burj khalifa",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: Popular3,
    altText: "Kyoto temple, japan",
    subtitle: "Japan",
    title: "Kyoto temple",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: Popular3,
    altText: "Kyoto temple, japan",
    subtitle: "Japan",
    title: "Kyoto temple",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
];

export default function Tours() {
  return (
    <>
      <Header />

      <main>
        {/* POPULAR */}
        <section class="popular" id="destination">
          <div class="container">
            <h2 class="h2 section-title">Our Cities</h2>

            <p class="section-text">
              Fusce hic augue velit wisi quibusdam pariatur, iusto primis, nec
              nemo, rutrum. Vestibulum cumque laudantium. Sit ornare mollitia
              tenetur, aptent.
            </p>

            <CardList cards={cards} />
            {/* <ul class="popular-list">
              <li>
                <div class="popular-card">
                  <figure class="card-img">
                    <Image
                      src={Popular1}
                      alt="San miguel, italy"
                      loading="lazy"
                    />
                  </figure>

                  <div class="card-content">
                    <div class="card-rating">
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                    </div>

                    <p class="card-subtitle">
                      <a href="#">Italy</a>
                    </p>

                    <h3 class="h3 card-title">
                      <a href="#">San miguel</a>
                    </h3>

                    <p class="card-text">
                      Fusce hic augue velit wisi ips quibusdam pariatur, iusto.
                    </p>
                  </div>
                </div>
              </li>

              <li>
                <div class="popular-card">
                  <figure class="card-img">
                    <Image
                      src={Popular2}
                      alt="Burj khalifa, dubai"
                      loading="lazy"
                    />
                  </figure>

                  <div class="card-content">
                    <div class="card-rating">
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                    </div>

                    <p class="card-subtitle">
                      <a href="#">Dubai</a>
                    </p>

                    <h3 class="h3 card-title">
                      <a href="#">Burj khalifa</a>
                    </h3>

                    <p class="card-text">
                      Fusce hic augue velit wisi ips quibusdam pariatur, iusto.
                    </p>
                  </div>
                </div>
              </li>

              <li>
                <div class="popular-card">
                  <figure class="card-img">
                    <Image
                      src={Popular3}
                      alt="Kyoto temple, japan"
                      loading="lazy"
                    />
                  </figure>

                  <div class="card-content">
                    <div class="card-rating">
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                      <ion-icon name="star"></ion-icon>
                    </div>

                    <p class="card-subtitle">
                      <a href="#">Japan</a>
                    </p>

                    <h3 class="h3 card-title">
                      <a href="#">Kyoto temple</a>
                    </h3>

                    <p class="card-text">
                      Fusce hic augue velit wisi ips quibusdam pariatur, iusto.
                    </p>
                  </div>
                </div>
              </li>
            </ul> */}

            <button class="btn btn-primary">More destintion</button>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}
