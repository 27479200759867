// const axios = require("axios");
// const { PASSENGERINFO } = require("../components/Checkout");
// const dayjs = require("dayjs");
import axios from "axios";
import dayjs from "dayjs";

export const fetchProductAvailabilities = async ({ rezdyId, startDate }) => {
  const {
    data: { data: productAvailabities },
  } = await axios.post(
    `${process.env.REACT_APP_AVAILABILITY_URI}/availability`,
    {
      code: rezdyId,
      startDate: dayjs(startDate).startOf("month").format("YYYY-MM-DD"),
      endDate: dayjs(startDate)
        .startOf("month")
        .add(2, "month")
        .format("YYYY-MM-DD"),
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (!productAvailabities) {
    const {
      data: { data: productAvailabities },
    } = await axios.get(
      `${process.env.REACT_APP_AVAILABILITY_URI}/firstAvailability/${rezdyId}`
    );
    return productAvailabities;
  }

  return productAvailabities;
};

export const updateProductAvailabitoes = async ({ rezdyId, startDate }) => {
  const {
    data: { data: productAvailabities },
  } = await axios.post(
    `${process.env.REACT_APP_AVAILABILITY_URI}/availability`,
    {
      code: rezdyId,
      startDate: dayjs(startDate).startOf("month").format("YYYY-MM-DD"),
      endDate: dayjs(startDate)
        .startOf("month")
        .add(1, "month")
        .format("YYYY-MM-DD"),
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return productAvailabities;
};

export default fetchProductAvailabilities;
