import { gql } from "@apollo/client";

const FEATUREDEXPERIENCES = gql`
  query FEATUREDEXPERIENCES(
    $dayTours: Boolean!
    $multiday: Boolean!
    $airportTransfers: Boolean!
  ) {
    homePage {
      header
      subheader
      allThings: all_things_to_do {
        product {
          id: boat_id
          name
          private
          slug
          duration
          price
          cardMessage: card_message
          cardSnippet: snippet
          carousel: card_media {
            url
            type: provider_metadata
          }

          reviews {
            id
            rating
          }
        }
      }
      dayTours: one_day_tours @include(if: $dayTours) {
        product {
          id: boat_id
          name
          slug
          duration
          private
          price
          cardMessage: card_message
          cardSnippet: snippet
          carousel: card_media {
            url
            type: provider_metadata
          }
          reviews {
            id
            rating
          }
        }
      }
      multiday: multi_day_tours @include(if: $multiday) {
        product {
          id: boat_id
          name
          slug
          duration
          private
          price
          cardMessage: card_message
          cardSnippet: snippet
          carousel: card_media {
            url
            type: provider_metadata
          }
          reviews {
            id
            rating
          }
        }
      }
      privateTours: airport_transfer @include(if: $airportTransfers) {
        product {
          id: boat_id
          name
          slug
          duration
          private
          price
          cardMessage: card_message
          cardSnippet: snippet
          carousel: card_media {
            url
            type: provider_metadata
          }
          reviews {
            id
            rating
          }
        }
      }
    }
    citiesDropdown: cities {
      id
      name
      slug
      card_media(limit: 1) {
        url
      }
    }

    totalProducts: productsConnection {
      aggregate {
        totalCount
      }
    }
    totalDayTours: productsConnection(where: { type: "DAYTOUR" }) {
      aggregate {
        count
      }
    }
    totalMultiDay: productsConnection(where: { type: "MULTIDAY" }) {
      aggregate {
        count
      }
    }
    totalAirportPickup: productsConnection(where: { type: "AIRPORTPICKUP" }) {
      aggregate {
        count
      }
    }
  }
`;
export default FEATUREDEXPERIENCES;
