import { Rating } from "react-simple-star-rating";
import Header from "../../components/header";
import Footer from "../../components/footer";
import HeroSlider from "../../components/heroSlider";
import TourBasics from "../../components/tourBasics";
import Included from "../../components/included";
import WhatWillDo from "../../components/whatWillDo";
import Popular1 from "../../assets/images/popular-1.jpg";
import Popular2 from "../../assets/images/popular-2.jpg";
import Popular3 from "../../assets/images/popular-3.jpg";
import Custom1 from "../../assets/images/custom-1.jpg";
import Gallery1 from "../../assets/images/gallery-1.jpg";
import Gallery4 from "../../assets/images/gallery-4.jpg";
import Gallery5 from "../../assets/images/gallery-5.jpg";
import HeroBanner from "../../assets/images/hero-banner.jpg";
import CardList from "../../components/cardList";
import HeroSwipper from "../../components/HeroSwipper";
import BreadcrumbComponent from "../../components/breadcrumb";
import Cart from "../../components/cart";
import DateAndPax from "../../components/DateAndPax";
import { useContext, useState } from "react";
import { PaxContext } from "../../utils/context/checkoutContext";
import { useNavigate, useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { FETCH_PRODUCT } from "../../api/product";
import getProductIdFromSlug from "../../utils/getProductIdFromSlug";
import { useEffect } from "react";
import Loading from "../../components/Loading";
import { FETCH_PRODUCT_PRIVATE } from "../../api/productPage";

const carousel = [
  {
    id: "802",
    name: "niagara-falls-snap-4_wumh1p",
    alt: "",
    url: "https://res.cloudinary.com/see-sight-tours/video/upload/v1581476850/video/niagara-falls-snap-4_wumh1p.mp4",
    fragment: "v1581476850/video/niagara-falls-snap-4_wumh1p.mp4",
    type: {
      duration: "00:04",
      public_id: "video/niagara-falls-snap-4_wumh1p",
      resource_type: "video",
    },
  },
  {
    id: "803",
    name: "niagara-falls-snap-2_oug0yh",
    alt: "",
    url: "https://res.cloudinary.com/see-sight-tours/video/upload/v1581476847/video/niagara-falls-snap-2_oug0yh.mp4",
    fragment: "v1581476847/video/niagara-falls-snap-2_oug0yh.mp4",
    type: {
      duration: "00:04",
      public_id: "video/niagara-falls-snap-2_oug0yh",
      resource_type: "video",
    },
  },
  {
    id: "741",
    name: "Niagara Falls Walkway near the falls",
    alt: "Niagara Falls Walkway Near the Falls",
    url: "https://res.cloudinary.com/see-sight-tours/image/upload/v1581439822/niagara-falls-walkway.jpg",
    fragment: "v1581439822/niagara-falls-walkway.jpg\n",
    type: {
      public_id: "niagara-falls-walkway",
      resource_type: "image",
    },
  },
  {
    id: "139",
    name: "Illumination Tower Niagara Falls",
    alt: "Illumination Tower in Niagara Falls",
    url: "https://res.cloudinary.com/see-sight-tours/image/upload/v1581439774/Illumination-Tower.jpg",
    fragment: "v1581439774/Illumination-Tower.jpg",
    type: {
      public_id: "Illumination-Tower",
      resource_type: "image",
    },
  },
  {
    id: "193",
    name: "Table Rock House",
    alt: "Table Rock House",
    url: "https://res.cloudinary.com/see-sight-tours/image/upload/v1581439759/Table-Rock-House-Restaurant.png",
    fragment: "v1581439759/Table-Rock-House-Restaurant.png\n",
    type: {
      public_id: "Table-Rock-House-Restaurant",
      resource_type: "image",
    },
  },
  {
    id: "738",
    name: "Niagara Falls illuminated",
    alt: "Niagara Falls Illuminated",
    url: "https://res.cloudinary.com/see-sight-tours/image/upload/v1581439738/Niagara-Falls-Illuminated.jpg",
    fragment: "v1581439738/Niagara-Falls-Illuminated.jpg\n",
    type: {
      public_id: "Niagara-Falls-Illuminated",
      resource_type: "image",
    },
  },
  {
    id: "33",
    name: "Overlooking-the-Falls",
    alt: "Overlooking-the-Falls",
    url: "https://res.cloudinary.com/see-sight-tours/image/upload/v1581439693/Overlooking-the-Falls.jpg",
    fragment: "v1581439693/Overlooking-the-Falls.jpg\n",
    type: {
      public_id: "Overlooking-the-Falls",
      resource_type: "image",
    },
  },
  {
    id: "768",
    name: "See Sight Tour Guide greeting guests",
    alt: "See Sight Tour Guide Greeting Guests",
    url: "https://res.cloudinary.com/see-sight-tours/image/upload/v1581439684/see-sight-tour-guide-greeting-guests.jpg",
    fragment: "v1581439684/see-sight-tour-guide-greeting-guests.jpg\n",
    type: {
      public_id: "see-sight-tour-guide-greeting-guests",
      resource_type: "image",
    },
  },
];

const cards = [
  {
    imageSrc: Popular1,
    altText: "San miguel, italy",
    subtitle: "Italy",
    title: "San miguel",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: Popular2,
    altText: "Burj khalifa, dubai",
    subtitle: "Dubai",
    title: "Burj khalifa",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: Popular3,
    altText: "Kyoto temple, japan",
    subtitle: "Japan",
    title: "Kyoto temple",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
];

const tours = [
  {
    imageSrc: Popular1,
    altText: "San miguel, italy",
    subtitle: "Italy",
    title: "San miguel",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: Popular2,
    altText: "Burj khalifa, dubai",
    subtitle: "Dubai",
    title: "Burj khalifa",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: Popular3,
    altText: "Kyoto temple, japan",
    subtitle: "Japan",
    title: "Kyoto temple",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: Popular1,
    altText: "San miguel, italy",
    subtitle: "Italy",
    title: "San miguel",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: Popular2,
    altText: "Burj khalifa, dubai",
    subtitle: "Dubai",
    title: "Burj khalifa",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: Popular3,
    altText: "Kyoto temple, japan",
    subtitle: "Japan",
    title: "Kyoto temple",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
];

export default function Tour() {
  const { updateTour, pax } = useContext(PaxContext);
  const navigate = useNavigate();

  const [breadcrumb, setBreadcrumb] = useState([]);

  const { slug } = useParams();
  const productId = getProductIdFromSlug(slug);
  const { data, loading, error } = useQuery(FETCH_PRODUCT_PRIVATE, {
    // variables: { id: getProductIdFromSlug(slug) },
    variables: { slug, Id: productId },
  });

  useEffect(() => {
    console.log("Data:", slug, productId, data);
    setBreadcrumb([
      {
        title: data?.product?.[0]?.cities?.[0]?.name || "",
        link: "/",
      },
      {
        title: data?.product?.[0]?.name,
        link: "/",
      },
    ]);
  }, [data]);

  const updateTourContext = () => {
    updateTour({
      tour: "Best of Niagara Falls, Canada",
      tourId: "0f85e49f-b0b9-486b-b900-1eae9e1609e4",
      tourImage: carousel.map((item) => item.url)[0],
      private: false,
      rating: 4.6,
      guest: 796,
      message: "product.cardMedia",
    });
    navigate("/checkout");
  };

  return (
    <>
      <Header />

      {loading && <Loading />}

      {!loading && !error && data && (
        <main>
          <article>
            <div className="w-12/12 mx-auto">
              <br />
              <br />
              <br />
              <br />
              <HeroSwipper media={data?.product?.[0]?.carousel} />

              {/* <HeroSlider
              title="Our Cities"
              photos={[
                Custom1,
                Gallery1,
                Gallery4,
                Gallery5,
                HeroBanner,
                Custom1,
                Custom1,
                Custom1,
                Custom1,
                Custom1,
              ]}
            /> */}
            </div>

            {/* HERO Slider */}
            <div className="container">
              <div className="container">
                <div className="flex flex-row">
                  <div className="w-3/4">
                    <br />
                    <br />
                    <BreadcrumbComponent breadcrumbs={breadcrumb} />

                    <h1
                      className="md:px-[5%]  md:pl-0 text-[20px] xl:text-5xl font-extrabold mt-8 mb-2 text-gray-900"
                      id="what-you-will-see"
                    >
                      {data?.product?.[0]?.name}
                    </h1>

                    <span className="flex items-center gap-1">
                      <Rating
                        initialValue={4.5}
                        allowFraction={true}
                        disableFillHover={true}
                        fillColor="orange"
                        emptyColor="#9B9B9B"
                        readonly={true}
                        SVGstyle={{
                          display: "inline-block",
                        }}
                        size={15}
                      />
                      <span className="font-semibold text-black">4.5</span>
                      <span className="text-black">(5)</span>
                    </span>

                    <div className="flex my-10   justify-start flex-col gap-5 w-full ">
                      <div className="mmd:block  " id="mobile-pax">
                        <TourBasics
                          duration={data?.product?.[0]?.duration}
                          people={7}
                          name={data?.product?.name}
                        />
                      </div>
                    </div>

                    <h1 className="text-[20px] font-bold mt-8 mb-2 text-black">
                      What's included
                    </h1>
                    <Included list={data?.product?.[0]?.tourIncludes || []} />

                    <br />

                    <h1 className="text-[20px] font-bold mt-8 mb-2 text-black">
                      What you'll see
                    </h1>
                    <CardList
                      cards={(data?.product?.[0]?.attractions || []).map(
                        (attraction, index) => ({
                          imageSrc:
                            attraction?.cardMedia?.[0]?.url ||
                            attraction?.cardMediaAlt?.[0]?.url,
                          altText:
                            attraction?.cardMedia?.[0]?.altText ||
                            attraction?.cardMediaAlt?.[0]?.url,
                          subtitle: "",
                          title: attraction?.name || "",
                          text: "",
                        })
                      )}
                      type={"Attraction"}
                    />

                    <br />

                    <h1 className="text-[20px] font-bold mt-8 mb-2 text-black">
                      What you'll do
                    </h1>
                    <WhatWillDo
                      list={
                        data?.product?.[0]?.longDescription?.split("\n\n") || []
                      }
                    />

                    <br />

                    <h1 className="text-[20px] font-bold mt-8 mb-2 text-black">
                      Important Information
                    </h1>
                    <>
                      {(data?.product?.[0]?.importantInfo || [])?.map(
                        (info, index) => (
                          <>
                            <p className="text-gray-950 text-lg">
                              {(typeof info === "string"
                                ? info
                                : info?.value) || ""}
                            </p>
                            {data?.product?.[0]?.importantInfo?.length !==
                              index + 1 && <br />}
                          </>
                        )
                      )}
                    </>

                    <br />

                    <h1 className="text-[20px] font-bold mt-8 mb-2 text-black">
                      Related Tours
                    </h1>
                    <CardList
                      cards={(data?.product?.[0]?.relatedProducts || []).map(
                        (product) => {
                          return {
                            imageSrc: product?.cardMedia?.[0]?.url,
                            altText: product?.cardMedia?.[0]?.alt,
                            subtitle: product?.cardMedia?.[0]?.type?.public_id,
                            title:
                              product?.name?.length < 30
                                ? product?.name
                                : product?.name?.substring(0, 30) + "...",
                            text: "",
                          };
                        }
                      )}
                    />

                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="w-1/4 sticky top-0 h-max">
                    <DateAndPax
                      rezdyId={"PC7CLZ"}
                      updateTourContext={updateTourContext}
                      mobile={true}
                      privateTour={false}
                      tourId={"0f85e49f-b0b9-486b-b900-1eae9e1609e4"}
                      adult={159}
                      child={99}
                      infant={20}
                      name={"Best of Niagara Falls, Canada"}
                      modal={true}
                      detailsLoading={false}
                      // detailsLoading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </article>
        </main>
      )}

      <Footer />
    </>
  );
}
