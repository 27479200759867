const googleAnalyticsTransaction = async (data) => {
  const { items, name, id, ...rest } = data;
  const googleAnalyticsItems = [];

  items.map((ageGroup) => {
    if (ageGroup.count > 0) {
      googleAnalyticsItems.push({
        name,
        id,
        price: Number(ageGroup.price.split("$")[1]),
        quantity: ageGroup.count,
        variant: ageGroup.title,
      });
    }
  });
  //@ts-ignore
  // @ts-ignore
  const res = await window.gtag("event", "purchase", {
    ...rest,
    currency: "USD",
    items: googleAnalyticsItems,
  });
  // @ts-ignore
  //   await window.fbq('track', 'Purchase', { currency: 'USD', value: rest.value });
};

export const view_item = async (data) => {
  // await window.gtag('event', 'view_item', {
  //   ...data
  // });
  return;
};
export const add_to_cart = async (data) => {
  // await window.gtag('event', 'add_to_cart', {
  //   ...data
  // });
  return;
};

export const googleAnalyticsAddtoCart = async (data, event) => {
  const { items, name, id, ...rest } = data;
  const googleAnalyticsItems = [];

  items.map((ageGroup) => {
    if (ageGroup.count > 0) {
      googleAnalyticsItems.push({
        name,
        id,
        price: Number(ageGroup.price.split("$")[1]),
        quantity: ageGroup.count,
        variant: ageGroup.title,
      });
    }
  });
  //@ts-ignore
  // @ts-ignore
  const res = await window.gtag("event", event, {
    ...rest,
    currency: "USD",
    items: googleAnalyticsItems,
  });
  // @ts-ignore
  //   await window.fbq('track', 'Purchase', { currency: 'USD', value: rest.value });
};
export default googleAnalyticsTransaction;
