import { Swiper, SwiperSlide } from "swiper/react";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import styled from "styled-components";
import SwiperCore, { Navigation } from "swiper";
// import {
//   optimizeImageUrl,
//   optimizeVideoUrl,
// } from "../../utils/videoLinkOptimize";

const StyledImage = styled.img`
  min-height: 450px !important;
  max-height: 450px;
  @media (max-width: 1000px) {
    max-height: 300px;
    min-height: 300px !important;
  }
`;
const StyledVideo = styled.video`
  min-height: 450px !important;
  max-height: 450px;
  @media (max-width: 1000px) {
    max-height: 300px;
    min-height: 300px !important;
  }
`;

const SwipperParams = {
  className: "w-full   styleswipper ",
  spaceBetween: 10,
  slidesPerView: 3,
  effect: "slide",
  grabCursor: true,
  a11y: { enabled: true },
  observer: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
    navigation: true,
  },
  observeParents: true,
  observeSlideChildren: true,
  breakpoints: {
    300: {
      slidesPerView: 1,
    },
    400: {
      slidesPerView: 1,
    },
    600: {
      slidesPerView: 2,
    },
    800: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    },
  },
};

const HeroSwipper = ({ media }) => {
  SwiperCore.use([Navigation]);
  return (
    <Swiper {...SwipperParams}>
      {media.map((media, index) => {
        return (
          <SwiperSlide key={media.url} className="w-2/6 min-h-full  ">
            {media.url.includes("jpg") || media.url.includes("png") ? (
              <StyledImage
                // src={optimizeImageUrl(media.url)}
                src={media.url}
                width={500}
                height={300}
                alt={media.url}
                className="w-full object-fill h-full "
              />
            ) : (
              <>
                <StyledVideo
                  playsInline
                  autoPlay
                  muted
                  loop
                  className="w-full object-fill h-full  "
                  src={media.url}
                  //   src={optimizeVideoUrl(media.url)}
                />
              </>
            )}
          </SwiperSlide>
        );
      })}
      <div className="swiper-pagination"></div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
    </Swiper>
  );
};

export default HeroSwipper;
