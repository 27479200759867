import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import CardList from "../../components/cardList";
import { useQuery } from "@apollo/client";
import Loading from "../../components/Loading";
import { useParams } from "react-router";
import { FETCH_CITY } from "../../api/cityPage";
import niagaraFallsTours from "../../data/tours/niagara-falls-tours-canada";
import halifaxTours from "../../data/tours/halifax-tours";
import torontoTours from "../../data/tours/toronto-tours";
import princeEdwardIslandTours from "../../data/tours/prince-edward-island-tours";

export default function CityTours() {
  const { slug } = useParams();
  const [city, setCity] = useState(null);
  const [cards, setCards] = useState([]);
  // const { data, loading, error } = useQuery(FETCH_CITY, {
  //   variables: {
  //     slug,
  //   },
  // });
  useEffect(() => {
    // if (data) {
    // console.log(data);
    let data = null;
    switch (slug) {
      case "niagara-falls-tours-canada":
        data = niagaraFallsTours;
        break;
      case "halifax-tours":
        data = halifaxTours;
        break;
      case "toronto-tours":
        data = torontoTours;
        break;
      case "prince-edward-island-tours":
        data = princeEdwardIslandTours;
        break;
    }
    setCity(data?.cities?.[0]);
    setCards(
      data?.[0]?.products?.map((tour) => {
        //   tour = tour?.product;
        return {
          slug: tour?.slug,
          imageSrc: tour?.cardMedia?.[0]?.url,
          altText: tour?.slug,
          subtitle: tour?.cardMessage,
          title: tour?.name,
          text: "",
        };
      })
    );
    // }
  }, []);

  return (
    <>
      <Header />

      {/* {loading && <Loading />} */}
      {/* {!loading && city && ( */}
      <main>
        {/* POPULAR */}
        <section className="popular" id="destination">
          <div className="container">
            <h2 className="h2 section-title">{city?.name}</h2>
            <p className="section-text">Small Group Tours {city?.name}</p>
            <CardList cards={cards} />
          </div>
        </section>
      </main>
      {/* )} */}
      <Footer />
    </>
  );
}
