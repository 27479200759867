import { gql } from "@apollo/client";

const FETCH_PRODUCT_PRIVATE = gql`
  query FETCH_PRODUCT_PRIVATE($slug: JSON!, $Id: String) {
    product: products(where: { slug: $slug }) {
      id: boat_id
      name
      slug
      metaDescription: meta_description
      canonical
      pageTitle: title
      type
      trustBar: trust_bar
      shortDescription: short_description
      longDescription: long_description
      cardMessage: card_message
      trustBar: trust_bar
      tourIncludes: tour_includes
      importantInfo: important_info
      duration
      price
      FAQ
      carousel: carousel_media {
        id
        name
        alt: alternativeText
        url
        fragment: caption
        type: provider_metadata
      }
      cities {
        id
        name
        slug
      }
      reviews {
        id
        rating
      }
      attractions {
        id
        name
        slug
        cardSnippet: snippet
        cardMedia: card_media {
          id
          name
          alt: alternativeText
          url
          fragment: caption
          type: provider_metadata
        }
        cardMediaAlt: media_library {
          id
          name
          alt: alternativeText
          url
          fragment: caption
          type: provider_metadata
        }
        city {
          id
          name
          slug
        }
      }
      relatedProducts: related_products {
        id: boat_id
        name
        slug
        duration
        price
        cardSnippet: snippet
        cardMessage: card_message
        cardMedia: card_media {
          id
          name
          alt: alternativeText
          url
          fragment: caption
          type: provider_metadata
        }
        carousel: carousel_media {
          id
          name
          alt: alternativeText
          url
          fragment: caption
          type: provider_metadata
        }
        reviews {
          id
          rating
        }
      }
      cardMedia: card_media {
        url
      }
      private
    }
    reviews(where: { product: { slug: $slug } }) {
      id
      review
      title
      traveller
      rating
      date
      source
      created_at
    }
    rezdy: boatnew_products(where: { slug: { _eq: $Id } }) {
      id
      rezdy {
        rezdyId: rezdy_id
      }
    }
  }
`;

const FETCH_PRODUCT_CAROUSAL = gql`
  query FETCH_PRODUCT_PRIVATE($slug: JSON!, $Id: String) {
    product: products(where: { slug: $slug }) {
      id: boat_id
      name
      slug
      metaDescription: meta_description
      canonical
      pageTitle: title
      type
      trustBar: trust_bar
      shortDescription: short_description
      longDescription: long_description
      cardMessage: card_message
      trustBar: trust_bar
      tourIncludes: tour_includes
      importantInfo: important_info
      duration
      price
      FAQ
      carousel: carousel_media {
        id
        name
        alt: alternativeText
        url
        fragment: caption
        type: provider_metadata
      }
    }
  }
`;

const FETCH_PRODUCT_DETAILS = gql`
  query FETCH_PRODUCT_DETAILS($slug: JSON!) {
    productDetails(where: { slug: $slug }) {
      rezdyId
      adult
      child
      infant
      options {
        id
        start_time
      }
    }
  }
`;

const FETCH_PRODUCT_URL = gql`
  query FETCH_PRODUCT_URL($id: JSON!) {
    product: products(where: { boat_id: $id }) {
      cardMedia: card_media {
        url
      }
    }
  }
`;

const FETCH_PRODUCT_BOAT = gql`
  query FETCH_PRODUCT_BOAT($id: String!) {
    product: boatnew_products_by_pk(id: $id) {
      id
      quantityMin: quantity_min
      quantityMax: quantity_max
      cancelCutOff: cancel_cutoff
      citiesProducts: cities_products {
        city {
          timeZone: time_zone
          gmb_link
        }
      }
    }
  }
`;

export {
  FETCH_PRODUCT_PRIVATE,
  FETCH_PRODUCT_DETAILS,
  FETCH_PRODUCT_URL,
  FETCH_PRODUCT_BOAT,
  FETCH_PRODUCT_CAROUSAL,
};
