import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import CardList from "../../components/cardList";
import Popular1 from "../../assets/images/popular-1.jpg";
import Popular2 from "../../assets/images/popular-2.jpg";
import Popular3 from "../../assets/images/popular-3.jpg";
import { useQuery } from "@apollo/client";
import { CITIES_PAGE } from "../../api/citiesPage";
import { Box, LinearProgress } from "@mui/material";
import { Text } from "@chakra-ui/layout";
import cities from "../../data/cities";

export default function Cities() {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(
      cities?.map((city) => {
        city = city?.city;
        return {
          slug: city?.slug,
          imageSrc: city?.cardMedia?.[0]?.url,
          altText: city?.cardMedia?.[0]?.alt,
          subtitle: city?.name,
          title: city?.name,
          text: city?.cardSnippet,
        };
      })
    );
  }, []);

  return (
    <>
      <Header />
      <main>
        {/* POPULAR */}
        <section className="popular" id="destination">
          <div className="container">
            <h2 className="h2 section-title">Our Cities</h2>
            <p className="section-text">
              Let us show you the places we call home
            </p>
            <CardList cards={cards} type={"City"} />
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
