import { gql } from "@apollo/client";

const FETCH_PRODUCT_DETAILS = gql`
  query FETCH_PRODUCT_DETAILS($slug: JSON!) {
    boatnew_products: productDetails(where: { slug: $slug }) {
      rezdyId
      adult
      child
      infant
    }
  }
`;

export const FETCH_PRODUCT = gql`
  query FETCH_PRODUCT($id: String!) {
    product: boatnew_products_by_pk(id: $id) {
      id
      name
      code
      type
      active
      advertisedPrice: advertised_price
      bookingCutoff: booking_cutoff
      cancelCutoff: cancel_cutoff
      duration
      pickupBounds: pickup_bounds
      pickupRequired: pickup_required
      quantityMax: quantity_max
      quantityMin: quantity_min
      product_prices(where: { currency: { _eq: "USD" } }) {
        id
        prices
      }
      advertised_price
      quantity
      quantity_max
      quantity_min
      productUnits: products_units(order_by: { unit: { label: asc } }) {
        unit_id
        updated_at
      }
      citiesProducts: cities_products {
        city {
          name
          id
          timeZone: time_zone
        }
      }
      rezdy {
        rezdy_id
      }
      availabilities {
        id
        start_date
        end_date
        start_time
        total_seats
      }
      strapi {
        id
        name
        slug
        meta_description
        title
        type
        short_description
        snippet
        long_description
        card_message
        price
        tour_includes
        important_info
        published_at
        created_by
        updated_by
        duration
        FAQ
        card_media
        banner_media
        canonical
        trust_bar
        blog
        boat_id
        private
      }
    }
    cities: boatnew_cities(order_by: { name: asc }) {
      id
      name
      coordinates
      pickupBounds: pickup_bounds
    }
    vehicles: boatnew_vehicles {
      id
      capacity
    }
  }
`;

export default FETCH_PRODUCT_DETAILS;
