import React, { SetStateAction, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styled from "styled-components";
const StyledPhoneInput = styled(PhoneInput)`
  input {
    font-size: 18px;
    padding: 0.5rem;
    color: black;
    border: 1px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    outline-width: 1px;
    outline-color: ${(props) => (props.valid ? "none" : "red")};
  }
  select {
    background: white;
    color: black;
    option {
      color: black;
    }
  }
`;
const StylednewPhoneInput = styled(PhoneInput)`
  input {
    outline: none;
    height: 2rem;
    font-size: 14px;
    @media (min-width: 768px) {
      height: 2.5rem;
      font-size: 18px;
    }
  }
  select {
    background: white;
    color: black;
    option {
      color: black;
    }
  }
`;
const Phone = ({ setValidPhone, setPhone, phone, validPhone, style }) => {
  useEffect(() => {
    if (phone && phone.length > 1) {
      setValidPhone(isValidPhoneNumber(phone));
    }
  }, [phone]);

  const PhoneInputComponent = style ? StylednewPhoneInput : StyledPhoneInput;

  return (
    <PhoneInputComponent
      value={phone}
      onChange={setPhone}
      defaultCountry="CA"
      withCountryCallingCode
      international
      valid={validPhone}
      key="phone"
      style={style}
    />
  );
};
export default Phone;
