import { Link } from "react-router-dom";
import LogoSVG from "../../assets/images/logo.svg";

const Footer = () => {
  return (
    <>
      {/* Footer */}
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="footer-brand">
              <Link to="/" className="logo">
                <strong
                  style={{
                    color: "#3b79c9",
                    fontWeight: "bolder",
                    fontSize: "2rem",
                  }}
                >
                  TRIPPATRON
                </strong>
              </Link>

              <p className="footer-text">
                Trippatron is transforming global travel by offering online
                planning and booking for tours in top cities and partnerships
                with major attractions, enhancing and inspiring journeys
                worldwide.
              </p>
            </div>

            <div className="footer-contact">
              <h4 className="contact-title">Contact Us</h4>

              <p className="contact-text">
                Feel free to contact and reach us !!
              </p>

              <ul>
                <li className="contact-item">
                  <ion-icon name="call-outline"></ion-icon>

                  <a href="tel:+01123456790" className="contact-link">
                    +1 (505) 4416 506
                  </a>
                </li>

                <li className="contact-item">
                  <ion-icon name="mail-outline"></ion-icon>

                  <a href="mailto:info@trippatron.com" className="contact-link">
                    info@trippatron.com
                  </a>
                </li>

                <li className="contact-item">
                  <ion-icon name="location-outline"></ion-icon>

                  <address>Niagara Falls, Canada</address>
                </li>
              </ul>
            </div>

            <div className="footer-form">
              <p className="form-text">
                Subscribe our newsletter for more update & news !!
              </p>

              <form action="" className="form-wrapper">
                <input
                  type="email"
                  name="email"
                  className="input-field"
                  placeholder="Enter Your Email"
                  required
                />

                <button type="submit" className="btn btn-secondary">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <p className="copyright">
              &copy; 2022 <a href="">Trippatron</a>. All rights reserved
            </p>

            <ul className="footer-bottom-list">
              <li>
                <a href="#" className="footer-bottom-link">
                  Privacy Policy
                </a>
              </li>

              <li>
                <a href="#" className="footer-bottom-link">
                  Term & Condition
                </a>
              </li>

              <li>
                <a href="#" className="footer-bottom-link">
                  FAQ
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      {/* GO TO TOP */}
      <a href="#top" className="go-top" data-go-top>
        <ion-icon name="chevron-up-outline"></ion-icon>
      </a>

      {/* custom js link */}
      <script src="./assets/js/script.js"></script>

      {/* ionicon link */}
      <script
        type="module"
        src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"
      ></script>
      <script
        nomodule
        src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"
      ></script>
    </>
  );
};

export default Footer;
