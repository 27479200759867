import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import CardList from "../../components/cardList";
import { useQuery } from "@apollo/client";
import Loading from "../../components/Loading";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Rating } from "@mui/material";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const reviews = [
  {
    id: 10141,
    title: "Excellent",
    review:
      "Manish was a fantastic guide! The tour was informative and entertaining. In addition, his time management was exceptional.",
    traveller: "David",
    rating: 5,
    date: "2024-06-08T04:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-09T00:28:39.66+00:00",
    product: 24,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10140,
    title: "Canada Boat tour",
    review:
      "Manish was an amazing guide. He was very efficient with our time and made sure we knew the best way to experience each attraction, along with great facts about the area. I could not imaging a better morning in Niagara thanks to Manish!",
    traveller: "Brittany  Podsobinski",
    rating: 5,
    date: "2024-06-08T04:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-08T19:27:16.15+00:00",
    product: 24,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10139,
    title: "NA",
    review:
      "We had Keith as our tour guide for the city drive and he was phenomenal, pointed out all the interesting things in St. John’s you don’t normally get to know from Google searches. He was funny, personable and made my friend and I feel completely at ease! If you want to do q quick, non strenuous city tour of St. John’s while in Newfoundland I Highly recommend this tour and Keith as your tour guide!",
    traveller: "Liana Perez",
    rating: 5,
    date: "2024-06-08T02:30:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-08T15:02:41.499+00:00",
    product: 191,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10135,
    title: "Ms",
    review:
      "Ms Vaishali was our tour guide. She was excellent. She was very experience. Always polite and patient. She was amazing.  ",
    traveller: "Terry Nohtchick",
    rating: 5,
    date: "2024-06-05T04:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-05T10:01:19.583+00:00",
    product: 24,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10137,
    title: "Great Tour",
    review:
      "The tour was fabulous & Joaquin (Joe) was full of fun facts about Niagara Falls & Canada!\nThis is THE best tour & well worth taking! The boat ride to the falls, behind the falls, the tunnels, Skylon Tower, whirlpool rapids etc. ",
    traveller: "Donna",
    rating: 5,
    date: "2024-06-04T04:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-06T01:19:46.698+00:00",
    product: 24,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10134,
    title: "Martine is fantastic",
    review:
      "Great tour, Martine provided the most friendly and locally knowledgeable tour.  Fanastic!",
    traveller: "Mark Becker",
    rating: 5,
    date: "2024-06-04T04:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-04T16:24:01.543+00:00",
    product: 24,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10136,
    title: "The Food Tour was Amazing",
    review:
      "First, and foremost, Joe MacDonald is an extraordinary fount of knowledge about seemingly everything on the Island.  His knowledge of ancient and modern history is incredible and very entertaining.  The food was fabulous.  Very top shelf and really showed off what's available in the area.  Joe also had plenty of suggestions for further explorations in the city and around the island.  If you are going to be in Prince Edward Island for more than a few days, start here and you'll get a great sampling of the Charlottetown food scene and some really useful tips for the rest of your stay.  Our thanks to Joe from Jeff and Suzi.",
    traveller: "Jeff Wong",
    rating: 5,
    date: "2024-06-04T03:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-05T11:30:40.953+00:00",
    product: 187,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10133,
    title: "Visitor",
    review:
      "This was a fantastic tour. Ruben provided great historical commentary, city facts, little known facts, and stories. You could tell he truly loves the city and loves to share. I highly recommend this tour!",
    traveller: "Kelly Schroeder",
    rating: 5,
    date: "2024-06-03T05:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-04T00:00:44.493+00:00",
    product: 71,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10132,
    title: "Tourist",
    review:
      " Nilah was such a great tour guide, she had so many stories and was so accomodating and kind. Highly recommend her for a guided tour.",
    traveller: "Baljeet",
    rating: 4.5,
    date: "2024-06-03T03:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-03T22:54:14.342+00:00",
    product: 38,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10129,
    title: "Mr",
    review:
      "Great tour, no waiting in lines, very smooth jump between attractions. Jim was great and we never felt rushed. ",
    traveller: "Chris Helton",
    rating: 5,
    date: "2024-06-01T04:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-02T18:42:17.58+00:00",
    product: 24,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10122,
    title: "Mr",
    review:
      "We had a wonderful journey in Niagara guided by Manish. A friendly, informed and dedicated guide. ",
    traveller: "Jorge Amador",
    rating: 5,
    date: "2024-05-30T04:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-05-31T16:03:41.313+00:00",
    product: 43,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10128,
    title: "Amazing tour with the best tour guide ",
    review:
      "We visited Peggy’s Cove at sunset with Nilah. What a great tour! Thoroughly explained, extremely fun, and the warmest of persons. I cannot recommend this visit enogh. The place is like heaven on Earth, and Nilah makes you feel like if you were part of her family. We loved it!",
    traveller: "Isabel P",
    rating: 5,
    date: "2024-05-30T04:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-06-01T01:25:10.061+00:00",
    product: 93,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10126,
    title: "Traveler",
    review:
      "Manish was the best tour guide. He made us feel welcome and special from the moment we met him. He made our trip so smooth and special. He showed us where to go every step of the way.  He even showed us the best places to take pictures and took pictures of us. This was the best thing we did while on vacation.  If you ever have the opportunity, you should book this trip and ask for Manish as your tour guide. Thank you Manish. ",
    traveller: "Tryna Walker",
    rating: 5,
    date: "2024-05-30T04:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-05-31T21:34:51.504+00:00",
    product: 43,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10120,
    title: "Amazing Experience with an Experienced and Knowledgeable Guide",
    review:
      "My boyfriend and I booked this tour to Lunenburg for the day. Our guide, Nilah Wedlake was kind, safe, entertaining, and informative. We learned and explored beautiful places. She introduced us to some hidden Nova Scotian gems. We would definitely visit parts of Nova Scotia with Nilah again.",
    traveller: "Anjali Chudasama",
    rating: 5,
    date: "2024-05-30T03:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-05-30T20:45:14.724+00:00",
    product: 145,
    city: null,
    __typename: "strapi_reviews",
  },
  {
    id: 10119,
    title: "Mrs ",
    review:
      "Manish did a fantastic job!!! And it was a fantastic experience because of his knowledge, safe driving , small group, comfortable car  making us all feel relaxed, & answering questions. He provided additional information when asked. It is evident that he loves giving tours and he loves sharing information related to other places nearby! This was an excellent tour!",
    traveller: "Patricia ",
    rating: 5,
    date: "2024-05-28T04:00:00+00:00",
    source: "WEBSITE",
    published_at: "2024-05-29T02:53:53.217+00:00",
    product: 24,
    city: null,
    __typename: "strapi_reviews",
  },
];

const ReviewComponent = ({ review }) => {
  return (
    <Card style={{ width: "100%" }}>
      <CardContent>
        {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography> */}
        <Typography variant="h5" component="div">
          {review.traveller}{" "}
          <Rating
            name="half-rating-read"
            defaultValue={review.rating}
            precision={0.5}
            readOnly
          />
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {review.title}
        </Typography>
        <Typography variant="body2">
          {review.review}
          <br />
          {'"a benevolent smile"'}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Reviews = () => {
  return (
    <>
      <Header />
      <div style={{ marginTop: "10vh" }}>
        <div className="container">
          <h1
            style={{
              textAlign: "center",
              fontSize: "3rem",
              marginBottom: "2rem",
              fontWeight: "bolder",
            }}
          >
            Reviews
          </h1>
          {reviews.map((review) => (
            <ReviewComponent key={review.id} review={review} />
          ))}

          <div
            style={{
              marginBottom: "20vh",
            }}
          ></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Reviews;
