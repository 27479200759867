import * as React from "react";
import { ChakraProvider, List, ListItem, ListIcon } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const steps = [
  {
    label: "Select campaign settings",
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: "Create an ad group",
    description:
      "An ad group contains one or more ads which target a shared set of keywords.",
  },
  {
    label: "Create an ad",
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];

export default function Included({ list }) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <ChakraProvider>
      <List spacing={3}>
        {list.map((item, index) => (
          <ListItem className="text-black text-lg">
            <ListIcon as={CheckCircleIcon} color="blue.500" />
            {(typeof item === "string" ? item : item?.value) || ""}
          </ListItem>
        ))}
        {/* <ListItem className="text-black text-lg">
          <ListIcon as={CheckCircleIcon} color="blue.500" />
          Boat cruise ride into the mist of the Falls aboard Niagara City
          Cruises Voyage To The Falls Boat Tour* (seasonal, operating April -
          November only, replaced with admission to Niagara's Fury and Butterfly
          Conservatory in the winter months)
        </ListItem>
        <ListItem className="text-black text-lg">
          <ListIcon as={CheckCircleIcon} color="blue.500" />
          Visit to Skylon Tower Observation Deck for an amazing aerial view of
          the Falls
        </ListItem>
        <ListItem className="text-black text-lg">
          <ListIcon as={CheckCircleIcon} color="blue.500" />
          Admission to Journey Behind the Falls to explore cave-like tunnels
          behind the Falls
        </ListItem>
        <ListItem className="text-black text-lg">
          <ListIcon as={CheckCircleIcon} color="blue.500" />
          Scenic, narrated driving tour of Niagara Falls featuring passing
          multiple points of interest including: Dufferin Islands, the
          International Control Dam, Old Scow, Whirlpool Rapids, and the Floral
          Clock*
        </ListItem>
        <ListItem className="text-black text-lg">
          <ListIcon as={CheckCircleIcon} color="blue.500" />
          Intimate, guided, small-group tour experience (groups are limited to 7
          people)
        </ListItem>
        <ListItem className="text-black text-lg">
          <ListIcon as={CheckCircleIcon} color="blue.500" />
          Free pick up and drop off in the downtown Niagara Falls area
        </ListItem> */}
      </List>
    </ChakraProvider>
  );
}
