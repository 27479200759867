import niagaraFallsTours from "../data/tours/niagara-falls-tours-canada";
import halifaxTours from "../data/tours/halifax-tours";
import torontoTours from "../data/tours/toronto-tours";
import princeEdwardIslandTours from "../data/tours/prince-edward-island-tours";

const products = [
  ...niagaraFallsTours[0].products,
  ...halifaxTours[0].products,
  ...torontoTours[0].products,
  ...princeEdwardIslandTours[0].products,
];

export default function getProductIdFromSlug(slug) {
  let productId = null;
  products.forEach((product) => {
    if (product.slug === slug) {
      productId = product.id;
    }
  });
  return productId;
}
