import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import CardList from "../../components/cardList";
import { useQuery } from "@apollo/client";
import Loading from "../../components/Loading";
import FEATUREDEXPERIENCES from "../../api/featuredexperiences";
import niagaraFallsTours from "../../data/tours/niagara-falls-tours-canada";
import halifaxTours from "../../data/tours/halifax-tours";
import torontoTours from "../../data/tours/toronto-tours";
import princeEdwardIslandTours from "../../data/tours/prince-edward-island-tours";

export default function DayTours() {
  const [cards, setCards] = useState([]);
  // const { data, loading, error } = useQuery(FEATUREDEXPERIENCES, {
  //   variables: {
  //     dayTours: true,
  //     multiday: false,
  //     airportTransfers: false,
  //   },
  // });
  useEffect(() => {
    // if (data)
    const tours = [
      ...niagaraFallsTours[0].products,
      ...halifaxTours[0].products,
      ...torontoTours[0].products,
      ...princeEdwardIslandTours[0].products,
    ];
    setCards(
      tours?.map((tour) => {
        // tour = tour?.product;
        return {
          slug: tour?.slug,
          imageSrc: tour?.cardMedia?.[0]?.url,
          altText: tour?.slug,
          subtitle: tour?.cardMessage,
          title: tour?.name,
          text: "",
        };
      })
    );
  }, []);

  return (
    <>
      <Header />

      {/* {loading && <Loading />} */}
      {/* {!loading && ( */}
      <main>
        {/* POPULAR */}
        <section className="popular" id="destination">
          <div className="container">
            <h2 className="h2 section-title">Day Tours</h2>
            <p className="section-text">
              Best Small Group Tours. Operating Across Canada and the United
              States
            </p>
            <CardList cards={cards} />
          </div>
        </section>
      </main>
      {/* )} */}
      <Footer />
    </>
  );
}
