import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
// import { useLazyQuery } from '@apollo/client';
import { loadStripe } from "@stripe/stripe-js";
// import { BOOKING_SEARCH, MY_TOURS_PAGE_INTERFACE } from '../../api/my-tours';
// import Head from "next/head";
// import Router from 'next/router';
import { PaxContext } from "../../utils/context/checkoutContext";
// import Script from "next/script";
// import MobileSumary from './component/MobileSumary';
import SinglePageCheckoutForm from "../../components/Checkout/singlePageForm";
import Summary from "../../components/Checkout/component/summary";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import dayjs from "dayjs";
import { add_to_cart } from "../../utils/googleTransaction";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useNavigate } from "react-router";

const ButtonDiv = styled.div`
  padding: 50px 20%;
  position: relative;
  z-index: 100;
  @media (max-width: 1200px) {
    padding: 50px 50px;
  }
  @media (max-width: 768px) {
    padding: 20px 20px;
    button: {
      width: 130px;
      height: 30px;
    }
  }
  display: flex;
  justify-content: end;
  gap: 2rem;
  .back {
    background: none;
    border: 2px solid #f15c5a;
  }
  button {
    width: 181px;
    height: 66px;
    color: white;
    left: calc(50% - 181px / 2 + 525.5px);
    top: 1098px;
    background: #f15c5a;
    border-radius: 10px;
  }
`;

const Checkout = () => {
  //@ts-ignore
  const { pax, tour, user, updatePax, updateUser } = useContext(PaxContext);
  const navigate = useNavigate();

  const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE));

  const [bookingId, setBookingId] = useState();

  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const [thankyou, setThankYou] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [customerId, setCustomerId] = useState("");

  useEffect(() => {
    if (!pax?.adults) {
      navigate("/");
    } else {
      add_to_cart({
        item_id: tour.Id,
        item_name: tour.tour,
        quantity: pax.adults.count + pax?.children?.count + pax?.infants?.count,
        currency_code: "USD",
        item_price: pax.totalPrice,
      });
      return () => {
        if (user?.name) {
          updateUser({});
          updatePax({});
        }
      };
    }
  }, [pax]);

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    return message;
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const createPaymentIntent = async (body) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_PAYMENT_API}/create-payment-intent`,
        {
          ...body,
        }
      );
      setClientSecret(res.data.clientSecret);
      setCustomerId(res.data.customer);
    } catch (error) {}
  };
  useEffect(() => {
    if (pax?.adults) {
      const body = {
        amount: pax.totalPrice,
        currency: "usd",
        tour: tour.tour,
        tourDate: dayjs(pax.selectedTimeSlot.startTime).format("MMMM DD ,YYYY"),
        tourTime: dayjs(pax.selectedTimeSlot.startTime).format("HH:MM:ss"),
      };
      createPaymentIntent(body);
    }
  }, []);
  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
    },
  };

  return (
    <>
      <script async src="https://r.wdfl.co/rw.js" data-rewardful="13f729" />
      <script id="rewardul">{`(function (w, r) {
        w._rwq = r;
        w[r] =
          w[r] ||
          function () {
            (w[r].q = w[r].q || []).push(arguments);
          };
      })(window, "rewardful");`}</script>
      <Header />
      <br />
      <br />
      <br />
      <br />
      {/* <MobileSumary /> */}
      {pax && (
        <div className=" h-auto  bg-cover w-full bg-no-repeat bg-center relative bg-white px-2  mb-6 ">
          <div className="flex w-full md:px-[20%] px-[4%] ">
            <button
              className=" text-gray-500 mt-3 "
              //   onClick={() => Router.back()}
            >
              {"<  "} {"Back"}
            </button>
          </div>
          <h1 className="w-full py-1 md:py-4 text-center text-2xl lg:text-3xl mt-2 md:mt-4  font-bold  ">
            Checkout
          </h1>
          <div className="flex  flex-wrap md:flex-nowrap w-full gap-4 px-2  sm:px-[15%] md:px-[5%]  2xl:px-[18%]  3xl:px-[22%] bg-white ">
            <Summary paymentLoading={paymentLoading} />
            {!clientSecret && (
              <div className=" w-[100%]  md:w-[50%] flex    justify-center items-center  ">
                <div className=" flex justify-center items-center    h-1/2   w-5/6">
                  <div
                    role="status"
                    className="flex justify-center items-center h-[50px] "
                  >
                    <svg
                      aria-hidden="true"
                      className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-700"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            )}
            {clientSecret && (
              <>
                {/* @ts-ignore */}
                <Elements stripe={stripePromise} options={options}>
                  <SinglePageCheckoutForm
                    stripePromise={stripePromise}
                    paymentLoading={paymentLoading}
                    clientSecret={clientSecret}
                    setPaymentLoading={setPaymentLoading}
                    customerId={customerId}
                  />
                </Elements>
              </>
            )}
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default Checkout;
