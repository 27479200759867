/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState } from "react";
// import { PASSENGERPAX } from '../index';
// import Image from 'next/image';
import styled from "styled-components";
import { PaxContext } from "../../../utils/context/checkoutContext";
import dayjs from "dayjs";

import Tooltip from "../../../utils/tooltip";
import { Rating } from "react-simple-star-rating";
const StyledRating = styled(Rating)`
  /*  .star-svg {
    height: 30px;
  } */
`;
export const Span = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 600;
`;

const Summary = ({ paymentLoading }) => {
  //@ts-ignore
  const { pax, tour } = useContext(PaxContext);

  // const childrenPax = children ? children?.count : 0;
  // const infantPax = infants ? infants?.count : 0;
  const [data, setDate] = useState(false);

  return (
    <div className=" w-[98%] md:mb-8  md:w-[46%]   flex-col  md:flex gap-4">
      <div
        className=" w-full
     flex flex-col  text-black   bg-white   border border-gray-200  p-5 justify-start items-center min-height-[220]"
      >
        {!isNaN(tour.rating) && (
          <div className="w-full flex flex-col mb-1 ">
            <div className=" flex xl:hidden flex-col gap-4  text-base uppercase   ">
              <Tooltip text={tour.tour}>
                <Span>
                  {tour.tour}{" "}
                  {tour.message === "AWARD WINNER!" && (
                    <span className="text-[#F15C5A]  ">({tour.message})</span>
                  )}{" "}
                </Span>
              </Tooltip>
            </div>
            <div>
              <span className=" text-base ">Rated {tour.rating}/5</span>
              <StyledRating
                SVGstyle={{
                  display: "inline-block",
                  textAlign: "center",
                }}
                readonly
                allowFraction
                initialValue={tour.rating}
                size={20}
              />
            </div>
          </div>
        )}
        <div className="flex gap-2 md:gap-4 items-center justify-start w-full">
          {tour?.tourImage?.includes("mp4") ? (
            <video
              src={tour.tourImage}
              autoPlay
              className=" min-w-[100%] xl:min-w-[300px] xl:max-w-[300px] shadow-md max-h-[150px]   "
            />
          ) : (
            <img
              src={tour.tourImage}
              width={200}
              height={200}
              alt="booking-image"
              className=" shadow-md min-w-[100%]   xl:min-w-[250px] xl:max-w-[300px]  max-h-[150px]  "
            />
          )}

          <div className=" hidden xl:flex flex-col gap-4  text-base uppercase   ">
            <Tooltip text={tour.tour}>
              <Span>
                {tour.tour}{" "}
                {tour.message === "AWARD WINNER!" && (
                  <span className="text-[#F15C5A]  ">({tour.message})</span>
                )}{" "}
              </Span>
            </Tooltip>
            <span>
              {dayjs(pax.selectedTimeSlot?.startTime).format(
                "ddd, DD MMM YYYY"
              )}
            </span>
            <span>
              {dayjs(pax.selectedTimeSlot?.startTime).format("hh:mm a")}
            </span>
          </div>
        </div>
        <div className=" flex xl:hidden mt-2  gap-4 w-full">
          <span>
            {dayjs(pax.selectedTimeSlot?.startTime).format("ddd, DD MMM YYYY")}
          </span>
          <span>
            {dayjs(pax.selectedTimeSlot?.startTime).format("hh:mm a")}
          </span>
        </div>
        <div className="flex flex-col w-full">
          {pax.adults && (
            <div className="flex     justify-between border-b-white border-b-2 items-center ">
              <div className="border-r-white border-r-2 w-1/2 py-2 items-center">
                <h2 className="w-full">
                  {" "}
                  {tour.private
                    ? pax.adults.label
                    : `${pax.adults.label} (13+)`}{" "}
                  <span> {!tour.private && ` x${pax.adults.count} `} </span>
                </h2>
              </div>
              <h2 className="w-1/3  text-end ">
                ${" "}
                {tour.private
                  ? Number(pax.adults.price).toFixed(2)
                  : Number(pax.adults.count * pax.adults.price).toFixed(2)}
              </h2>
              {/* <h2>x {`${children.count}`}</h2>
            <h2>{`${(children.count * Number(children.price)).toFixed(2)}`}</h2> */}
            </div>
          )}
          {pax.children && pax?.children?.count >= 1 && (
            <div className="flex     justify-between border-b-white border-b-2 ">
              <div className="border-r-white border-r-2 w-1/2 py-2">
                <h2>
                  {pax.children.label} (5-12){" "}
                  <span> {` x${pax.children.count} `} </span>{" "}
                </h2>
              </div>
              <h2 className="w-1/3 text-end">
                $ {Number(pax.children.count * pax.children.price).toFixed(2)}
              </h2>

              {/* <h2>x {`${children.count}`}</h2>
            <h2>{`${(children.count * Number(children.price)).toFixed(2)}`}</h2> */}
            </div>
          )}
          {pax.infants && pax.infants.count >= 1 && (
            <div className="flex     justify-between border-b-white border-b-2 ">
              <div className="border-r-white border-r-2 w-1/2 py-2">
                <h2 className="w-full">
                  {pax.infants.label} (Under 5){" "}
                  <span> {` x${pax.infants.count} `} </span>
                </h2>
              </div>
              <h2 className="w-1/3 text-end">
                $ {Number(pax.infants.count * pax.infants.price).toFixed(2)}
              </h2>

              {/*  <h2>x {`${infants.count}`}</h2>
            <h2>{`${(infants.count * Number(infants.price)).toFixed(2)}`}</h2> */}
            </div>
          )}
          <div className=" flex     border-t-2 border-gray-300      justify-between border-b-white border-b-2 ">
            <div className="border-r-white border-r-2 w-1/2 py-2">
              <h2 className="font-bold text-lg ">Total</h2>
            </div>
            <div className=" w-1/3 relative flex justify-end   ">
              <h2 className=" font-bold text-lg text-end w-full text-[#F15C5A] ">
                $ {pax.totalPrice.toFixed(2)}
              </h2>
              <span className=" text-xs absolute text-end 3xl:bottom-0 -bottom-[30%]  ">
                Prices shown in USD
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-full flex flex-col md:hidden">
        <button
          disabled={paymentLoading}
          className="py-3 w-full disabled:bg-gray-200  bg-[#F15C5A] rounded-md text-white text-xl "
          type="submit"
          form="payment-form"
        >
          {paymentLoading ? (
            <span className="animate-pulse">Processing</span>
          ) : (
            'Pay'
          )}
        </button>
        {paymentLoading && (
          <span className="text-red-500">
            Please do not navigate away from this page or close your browser
            while your payment is being processed. Doing so may result in an
            incomplete or failed transaction. Please wait until the payment
            processing is complete and you are redirected to a confirmation
            page. Thank you for your patience and understanding.
          </span>
        )}
      </div> */}
      <div className="hidden md:flex flex-col gap-4 slide-in  ">
        <h3 className="font-semibold text-xl ">Book With Confidence</h3>
        <div className="flex gap-2 mt-1   ">
          <img
            src="https://res.cloudinary.com/see-sight-tours/image/upload/v1691608033/icons-website/Untitled-1_Lowest_Price_Guarantee_up8kxd.svg"
            width={25}
            height={15}
            alt="lowest price"
          />
          <span className="text-base font-semibold ">
            Lowest Price Guarantee
          </span>
        </div>
        <div className="flex gap-2 mt-1 items-center  ">
          <img
            src="https://res.cloudinary.com/see-sight-tours/image/upload/v1691608033/icons-website/Untitled-1_Fee_Cancellation_Up_to_24hrs_in_advance_gr18kl.svg"
            width={25}
            height={15}
            alt="lowest price"
          />
          <span className="text-base font-semibold ">
            Free Cancellation Up to 24hrs in advance
          </span>
        </div>
        <div className="flex mt-1 gap-2 items-center  ">
          <img
            src="https://res.cloudinary.com/see-sight-tours/image/upload/v1691608033/icons-website/Untitled-1_Book_Securely_Pay_securely_lwow07.svg"
            width={25}
            height={40}
            alt="lowest price"
          />
          <span className="text-base font-semibold ">
            Book Securely Pay securely
          </span>
        </div>
        <div className="flex flex-col align-middle   ">
          <span className="text-xl font-semibold ">Call to book</span>

          <a
            href="tel:1-888-961-6584"
            className="text-lg gap-2 text-blue-400 flex "
          >
            <img src="/phonelogo.png" width={20} height={10} alt="phone logo" />
            <span>1-888-961-6584</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Summary;
