import { Link } from "react-router-dom";
import LogoSVG from "../../assets/images/logo-blue.svg";
import LogoBlue from "../../assets/images/logo-blue.svg";

const simpleNavbarLinkColor = "black";

const Header = ({ variant = "simple" }) => {
  if (variant === "simple") {
    return (
      <header className="header" data-header>
        <div className="overlay" data-overlay></div>

        <div
          className="header-top"
          style={{
            borderBottom: "0px",
            background: "white",
          }}
        >
          <div className="container">
            {/* <a href="tel:+01123456790" className="helpline-box">
              <div className="icon-box">
                <ion-icon name="call-outline"></ion-icon>
              </div>

              <div className="wrapper">
                <p className="helpline-title">For Further Inquires :</p>

                <p className="helpline-number">+01 (123) 4567 90</p>
              </div>
            </a> */}

            <Link to="/" className="logo">
              {/* <img src={LogoSVG} alt="Tourly logo" width={40} height={100} /> */}
              <strong style={{ color: "#3b79c9", fontWeight: "bolder" }}>
                TRIPPATRON
              </strong>
            </Link>

            <ul className="navbar-list">
              <li>
                <Link
                  to="/tours/day-tours"
                  className="navbar-link"
                  data-nav-link
                  style={{ color: simpleNavbarLinkColor, whiteSpace: "nowrap" }}
                >
                  Day Tours
                </Link>
              </li>

              {/* <li>
                <Link
                  to="/tours/private-tours"
                  className="navbar-link"
                  data-nav-link
                  style={{ color: simpleNavbarLinkColor, whiteSpace: "nowrap" }}
                >
                  Private Tours
                </Link>
              </li> */}

              <li>
                <Link
                  to="/cities"
                  className="navbar-link"
                  data-nav-link
                  style={{ color: simpleNavbarLinkColor, whiteSpace: "nowrap" }}
                >
                  cities
                </Link>
              </li>

              <li>
                <Link
                  to="/reviews"
                  className="navbar-link"
                  data-nav-link
                  style={{ color: simpleNavbarLinkColor, whiteSpace: "nowrap" }}
                >
                  reviews
                </Link>
              </li>

              {/* <li>
                <Link
                  to="/about-us"
                  className="navbar-link"
                  data-nav-link
                  style={{ color: simpleNavbarLinkColor, whiteSpace: "nowrap" }}
                >
                  about us
                </Link>
              </li> */}

              <li>
                <Link
                  to="/carrer"
                  className="navbar-link"
                  data-nav-link
                  style={{ color: simpleNavbarLinkColor, whiteSpace: "nowrap" }}
                >
                  Careers
                </Link>
              </li>
            </ul>

            {/* <div className="header-btn-group">
              <button className="search-btn" aria-label="Search">
                <ion-icon name="search"></ion-icon>
              </button>

              <button
                className="nav-open-btn"
                aria-label="Open Menu"
                data-nav-open-btn
              >
                <ion-icon name="menu-outline"></ion-icon>
              </button>
            </div> */}
          </div>
        </div>
      </header>
    );
  } else {
    return (
      <header className="header" data-header>
        <div className="overlay" data-overlay></div>

        <div className="header-top">
          <div className="container">
            <a></a>
            {/* <a href="tel:+01123456790" className="helpline-box">
              <div className="icon-box">
                <ion-icon name="call-outline"></ion-icon>
              </div>

              <div className="wrapper">
                <p className="helpline-title">For Further Inquires :</p>

                <p className="helpline-number">+01 (123) 4567 90</p>
              </div>
            </a> */}

            <Link to="/" className="logo">
              {/* <img src={LogoSVG} alt="Tourly logo" width={100} height={100} /> */}
              <strong
                style={{
                  color: "#3b79c9",
                  fontWeight: "bolder",
                  fontSize: "2rem",
                }}
              >
                TRIPPATRON
              </strong>
            </Link>

            <div></div>
            {/* <div className="header-btn-group">
              <button className="search-btn" aria-label="Search">
                <ion-icon name="search"></ion-icon>
              </button>

              <button
                className="nav-open-btn"
                aria-label="Open Menu"
                data-nav-open-btn
              >
                <ion-icon name="menu-outline"></ion-icon>
              </button>
            </div> */}
          </div>
        </div>

        <div className="header-bottom">
          <div className="container">
            <ul className="social-list">
              {/* <li>
                <a href="#" className="social-link">
                  <ion-icon name="logo-facebook"></ion-icon>
                </a>
              </li>
  
              <li>
                <a href="#" className="social-link">
                  <ion-icon name="logo-twitter"></ion-icon>
                </a>
              </li>
  
              <li>
                <a href="#" className="social-link">
                  <ion-icon name="logo-youtube"></ion-icon>
                </a>
              </li> */}
            </ul>

            <nav className="navbar" data-navbar>
              {/* <div className="navbar-top">
                <Link to="#" className="logo">
                  <img
                    src={LogoBlue}
                    alt="Tourly logo"
                    width={193}
                    height={52}
                  />

                  <strong style={{ color: "#3b79c9", fontWeight: "bolder" }}>
                    TRIPPATRON
                  </strong>
                </Link>

                <button
                  className="nav-close-btn"
                  aria-label="Close Menu"
                  data-nav-close-btn
                >
                  <ion-icon name="close-outline"></ion-icon>
                </button>
              </div> */}

              <ul className="navbar-list">
                <li>
                  <Link
                    to="/tours/day-tours"
                    className="navbar-link"
                    data-nav-link
                  >
                    Day Tours
                  </Link>
                </li>

                {/* <li>
                  <Link
                    to="/tours/private-tours"
                    className="navbar-link"
                    data-nav-link
                  >
                    Private Tours
                  </Link>
                </li> */}

                <li>
                  <Link to="/cities" className="navbar-link" data-nav-link>
                    cities
                  </Link>
                </li>

                <li>
                  <Link to="/reviews" className="navbar-link" data-nav-link>
                    reviews
                  </Link>
                </li>

                {/* <li>
                  <Link to="/about-us" className="navbar-link" data-nav-link>
                    about us
                  </Link>
                </li> */}

                <li>
                  <Link to="/carrer" className="navbar-link" data-nav-link>
                    Careers
                  </Link>
                </li>
              </ul>
            </nav>

            <div></div>
            {/* <button className="btn btn-primary">Book Now</button> */}
          </div>
        </div>
      </header>
    );
  }
};

export default Header;
