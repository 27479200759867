import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

const steps = [
  {
    label:
      "Your fun-filled, Niagara Falls tour begins with a complimentary downtown hotel pick up in the comfort and style of a Mercedes Metris van before heading to your first stop at the Skylon Tower.",
  },
  {
    label:
      "At the Skylon Tower, ascend 520 ft. in a glass elevator to the observation deck where you will be treated to an incredible, birds-eye view of all three Niagara Falls (Bridal Veil Falls, Horseshoe Falls, and the American Falls), as well as the Niagara River and surrounding area.",
  },
  {
    label:
      "Once you get back on the ground, your next stop will have you descend 125 ft (approx. 40m) to explore cave-like tunnels behind the Falls at Journey Behind the Falls. This experience is a treat for the senses as you’ll be able to not only hear the falls but feel them as well. The tunnels lead to two observation decks, giving you the perfect photo opportunity.",
  },
  {
    label:
      "After your visit to Journey Behind the Falls, we will take you to board the Niagara City Cruises Voyage To The Falls Boat Tour (seasonal, operating April - November only, replaced with admission to Niagara's Fury and Butterfly Conservatory in the winter months). On your cruise, you will sail around the Niagara gorge and get as close as possible to the Falls. You can expect to feel the refreshing mist on your skin during this boat ride but don’t worry, you will be provided with a recyclable rain poncho.",
  },
  {
    label:
      "During the final portion of your tour, your guide will take you on a narrated driving tour of the city where you will learn about Niagara’s rich history and distinct geography. During the driving portion of your tour, your guide will take you to several points of interest including the Dufferin Islands, the International Control Dam, the Old Scow, Whirlpool Rapids, and the Floral Clock.",
  },
  {
    label:
      "Enjoy complimentary downtown hotel drop you off to conclude your tour.",
  },
];

export default function WhatWillDo({ list }) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box>
      <Stepper activeStep={false} orientation="vertical">
        {list
          .filter((step) => step !== "")
          .map((step, index) => (
            <Step key={step.label}>
              <StepLabel>
                <span className="text-gray-950 text-lg">{step}</span>
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </Box>
  );
}
