/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
// import { useRouter } from "next/router";
// import Image from "next/image";

const Thankyou = ({ open, close, bookingId }) => {
  // const router = useRouter();

  const [seconds, setSeconds] = useState(15);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        // router.push(`/my-bookings/${bookingId}`);
        window.location.href = `/my-bookings/${bookingId}`;
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [seconds]);

  return (
    <div
      className={`${
        !open ? "hidden" : ""
      } h-[100vh] fixed z-[150] bg-black bg-opacity-60 top-0 left-0 w-screen flex  items-center justify-center  `}
    >
      <div className="flex items-start  ">
        <div className="bg-white py-[2%] lg:py-[8%] px-[2%]  flex items-center flex-col gap-4 transform slide-in transition-transform ease-in-out duration-1200 ">
          <img
            width={180}
            height={180}
            alt="logo"
            src="/success-tick.png"
            className="transform  translate-x-[30%]  slide-in transition-transform ease-in-out duration-1200"
          />
          <h2 className="text-3xl  text-black text-center   ">
            Thank you for booking with Tripshepherd!
          </h2>
          <p>We've sent your booking details to your email</p>
          <button
            className=" bg-[#F15C5A] text-white rounded-lg py-2 px-6 text-lg "
            onClick={() => {
              // router.push(`/my-bookings/${bookingId}`);
              window.location.href = `/my-bookings/${bookingId}`;
            }}
          >
            {`Continue to select your pick up location  (${seconds})`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
