import React from "react";
import { Box, LinearProgress } from "@mui/material";
import { Text } from "@chakra-ui/layout";

const Loading = () => {
  return (
    <Box sx={{ width: "80%", margin: "auto", marginY: "40vh" }}>
      <Text variant="h4" align="center" className="text-slate-500">
        Loading...
      </Text>
      <LinearProgress />
    </Box>
  );
};

export default Loading;
