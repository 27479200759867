import React from "react";
import { Link } from "react-router-dom";

function TourCard({ slug, imageSrc, altText, subtitle, title, text }) {
  return (
    <div className="popular-card hover:cursor-pointer">
      <Link to={`/tour/${slug || ""}`}>
        <figure className="card-img">
          <img
            src={imageSrc}
            alt={altText}
            loading="lazy"
            className="hover:opacity-75 cursor:transition-opacity cursor:duration-300 cursor:ease-in-out transform hover:scale-105"
            style={{
              transitionDuration: "0.3s",
              minHeight: "450px",
            }}
          />
        </figure>
        <div className="card-content">
          <div className="card-rating">
            {[...Array(5)].map((_, index) => (
              <ion-icon key={index} name="star"></ion-icon>
            ))}
          </div>
          <p className="card-subtitle">
            <Link to="#">{subtitle}</Link>
          </p>
          <h3 className="h3 card-title">
            <Link to="#">{title}</Link>
          </h3>
          <p className="card-text">{text}</p>
        </div>
      </Link>
    </div>
  );
}

export default TourCard;
