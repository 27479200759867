const cities = [
  {
    city: {
      id: "17",
      name: "Niagara Falls, Canada",
      slug: "niagara-falls-tours-canada",
      cardSnippet: "Honeymoon capital of the world",
      cardMedia: [
        {
          name: "Niagra Falls Canada.jpg",
          alt: "Niagara Falls Tours Canada",
          url: "https://res.cloudinary.com/see-sight-tours/image/upload/v1681456351/strapi/Niagra_Falls_Canada_06610840a9.jpg",
          fragment: "",
          type: {
            public_id: "strapi/Niagra_Falls_Canada_06610840a9",
            resource_type: "image",
          },
          __typename: "UploadFile",
        },
      ],
      __typename: "Cities",
    },
    __typename: "ComponentCitiesOrderCitiesOrder",
  },
  {
    city: {
      id: "2",
      name: "Toronto",
      slug: "toronto-tours",
      cardSnippet: "An innovative, multicultural hub and Canada's largest city",
      cardMedia: [
        {
          name: "Toronto.jpg",
          alt: "",
          url: "https://res.cloudinary.com/see-sight-tours/image/upload/v1681456539/strapi/Toronto_1bc190f4a8.jpg",
          fragment: "",
          type: {
            public_id: "strapi/Toronto_1bc190f4a8",
            resource_type: "image",
          },
          __typename: "UploadFile",
        },
      ],
      __typename: "Cities",
    },
    __typename: "ComponentCitiesOrderCitiesOrder",
  },
  {
    city: {
      id: "13",
      name: "Halifax",
      slug: "halifax-tours",
      cardSnippet:
        "Nova Scotia's capital, playing an important role in maritime history",
      cardMedia: [
        {
          name: "Halifax Night",
          alt: "Halifax Night",
          url: "https://res.cloudinary.com/see-sight-tours/image/upload/v1638292468/strapi/halifax_night_0968e0a115.jpg",
          fragment: "v1638292468/strapi/halifax_night_0968e0a115.jpg",
          type: {
            public_id: "strapi/halifax_night_0968e0a115",
            resource_type: "image",
          },
          __typename: "UploadFile",
        },
      ],
      __typename: "Cities",
    },
    __typename: "ComponentCitiesOrderCitiesOrder",
  },
  {
    city: {
      id: "14",
      name: "Prince Edward Island",
      slug: "prince-edward-island-tours",
      cardSnippet: null,
      cardMedia: [
        {
          name: "Green Gables Heritage Centre",
          alt: "Anne of Green Gables House PEI",
          url: "https://res.cloudinary.com/see-sight-tours/image/upload/v1581433933/Anne-of-Green-Gables-House.jpg",
          fragment: "v1581433933/Anne-of-Green-Gables-House.jpg\n",
          type: {
            public_id: "Anne-of-Green-Gables-House",
            resource_type: "image",
          },
          __typename: "UploadFile",
        },
      ],
      __typename: "Cities",
    },
    __typename: "ComponentCitiesOrderCitiesOrder",
  },
];

module.exports = cities;
