import React from "react";
import styled from "styled-components";

const StyleDiv = styled.div`
  @media (max-width: 650px) {
    gap: 4rem;
    justify-content: center;
    margin-bottom: 10px;
  }
  @media (max-width: 450px) {
    gap: 3rem;
    justify-content: center;
    margin-bottom: 10px;
  }
`;

const TourBasics = ({ duration, people, mobile, name }) => {
  return (
    <>
      <StyleDiv
        className={`${
          mobile ? "w-full" : "w-[inherit]"
        } flex justify-start gap-5 mmd:gap-20 md:items-end items-center `}
      >
        <div className="  flex flex-col gap-2  items-center ">
          <img
            src="/assets/clock_icon.png"
            width={20}
            height={20}
            alt="clock-icon"
          />
          <span className=" space-x-1   text-sm  md:text-sm mmd:text-base text-[#111111] text-center sm:text-start font-normal ">
            {" "}
            {duration} Hours
          </span>
        </div>
        <div className="  flex flex-col gap-2 justify-start  items-center ">
          <img
            src="/assets/persons_icon.png"
            width={22}
            height={22}
            alt="persons"
          />
          <span className=" space-x-1 text-sm   sm:text-sm md:text-sm mmd:text-base text-[#111111] text-center sm:text-start font-normal ">
            {" "}
            Up to {people} people
          </span>
        </div>
        {!name?.includes("Walking") && (
          <div className="  flex flex-col gap-2 justify-start  items-center ">
            <img
              src="/assets/transport_icon.png"
              width={22}
              height={22}
              alt="clock-icon"
            />
            <span className=" space-x-1 text-sm  sm:text-sm md:text-sm mmd:text-base text-[#111111] text-center sm:text-start font-normal ">
              {" "}
              Hotel Pick-up
            </span>
          </div>
        )}
      </StyleDiv>
    </>
  );
};

export default TourBasics;
