/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect, useState } from "react";
import Phone from "../../../utils/lazy-phone-validation";
import axios from "axios";
import dayjs from "dayjs";
// import Image from "next/image";
// import StripeCheckout from '../component/StripeCheckout';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaxContext } from "../../../utils/context/checkoutContext";
// import googleAnalyticsTransaction, {
//   googleAnalyticsAddtoCart,
// } from "../../../utils/googleTransaction";
import { toast } from "react-toastify";
// import { useRouter } from "next/router";
import Thankyou from "../Thanks";
// import { useAuthContext } from "../../../utils/context/authContext";

const SinglePageCheckoutForm = ({
  stripePromise,
  setPaymentLoading,
  paymentLoading,
  customerId,
  clientSecret,
}) => {
  // const authContext = useAuthContext();

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [thankYouPopUp, setThankYOuPopUp] = useState(false);
  const [bookingid, setBookingId] = useState(undefined);
  const [email, setEmail] = useState("");
  //@ts-ignore
  const { pax, tour, user } = useContext(PaxContext);
  const [validPhone, setValidPhone] = useState(true);
  const elements = useElements();
  const stripe = useStripe();
  // const router = useRouter();
  useEffect(() => {
    setEmail("");
    setName("");
    // setEmail(authContext.user !== null ? authContext.user.email : "");
    // setName(authContext.user !== null ? authContext.user.displayName : "");
    // }, [authContext.user]);
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validPhone || phone.length < 2) {
      setValidPhone(false);
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    // googleAnalyticsAddtoCart(
    //   {
    //     value: pax.totalPrice,
    //     name: name,
    //     id: tour.tourId,
    //     items: [
    //       {
    //         id: 1,
    //         title: "Adults",
    //         info: "Ages 13+",
    //         price: `$${pax.adults.price}`,
    //         count: pax.adults?.count,
    //       },
    //       {
    //         id: 2,
    //         title: "Children",
    //         info: "Ages 5-12",
    //         price: `$${pax.children ? pax.children.price : 0}`,
    //         count: pax.children ? pax.children.count : 0,
    //       },
    //       {
    //         id: 3,
    //         title: "Infants",
    //         info: "Ages under 5",
    //         price: `$${pax.infants ? pax.infants.price : 0}`,
    //         count: pax.infants ? pax.infants.count : 0,
    //       },
    //     ],
    //   },
    //   "begin_checkout"
    // );
    setPaymentLoading(true);
    const payment = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://www.seesight-tours.com/my-bookings",
      },
      redirect: "if_required",
    });
    const { error, paymentIntent } = payment;
    if (error) {
      setPaymentLoading(false);
      return;
    }

    const body = {
      customer_id: customerId,
      amount: pax.totalPrice,
      currency: "usd",
      email: email,
      name: name,
      phone: phone,
      adults: pax.adults.count,
      children: pax.children?.count,
      infants: pax.infants?.count,
      tour_date: dayjs(pax.selectedTimeSlot.startTime).format(),
      tour_time: pax.selectedTimeSlot.startTime.slice(11),
      time_zone: "America/Chicago",
      product_id: tour.tourId,
      passengersPrice: [
        {
          id: 1,
          title: "Adults",
          info: "Ages 13+",
          price: `$${pax.adults.price}`,
          count: pax.adults?.count,
        },
        {
          id: 2,
          title: "Children",
          info: "Ages 5-12",
          price: `$${pax.children ? pax.children.price : 0}`,
          count: pax.children ? pax.children.count : 0,
        },
        {
          id: 3,
          title: "Infants",
          info: "Ages under 5",
          price: `$${pax.infants ? pax.infants.price : 0}`,
          count: pax.infants ? pax.infants.count : 0,
        },
      ],
      payment_info: paymentIntent,
    };
    let res = null;
    try {
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_PAYMENT_API}/booking`,
        {
          ...body,
        }
      );
      res = response;
    } catch (error) {
      if (error) {
        setPaymentLoading(false);
      }
    }

    //@ts-ignore
    // window.rewardful("ready", function () {
    //   //@ts-ignore
    //   if (Rewardful.referral) {
    //     // Confirming the customer in stripe and calling with the returned customer email
    //     //@ts-ignore
    //     const result = window.rewardful("convert", {
    //       email: res.data?.customer ? res.data.customer.email : user.email,
    //     });
    //   }
    // });
    // if (`${process.env.NEXT_PUBLIC_MODE}` === "LIVE") {
    //   googleAnalyticsTransaction({
    //     transaction_id: res.data.booking_id,
    //     value: body.amount,
    //     name: tour.tour,
    //     id: body.product_id,
    //     items: body.passengersPrice,
    //   });
    // }

    setPaymentLoading(false);
    toast.success("Booking Created");
    setThankYOuPopUp(true);
    setBookingId(res.data.booking_id);
  };

  return (
    <div className=" w-full md:w-[50%]  px-1 ">
      {thankYouPopUp && (
        <Thankyou
          open={thankYouPopUp}
          close={setThankYOuPopUp}
          bookingId={bookingid}
        />
      )}
      <div className=" flex flex-col gap-1 mb-1">
        <h2 className="  text-xl font-semibold ">Contact details</h2>
        <p className="text-sm">
          We'll use this information to send you confirmation and updates about
          your booking
        </p>
      </div>
      <form
        className="w-full flex flex-col gap-4"
        onSubmit={(e) => handleFormSubmit(e)}
        id="payment-form"
      >
        <div className="flex-col">
          {/* <label className="  ">Name</label> */}
          <input
            type="text"
            placeholder="Name"
            className="p-2  rounded-md  w-full border border-gray-200 "
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            minLength={2}
            maxLength={100}
          />
        </div>
        <div className="flex flex-col">
          {/* <label className="">Email</label> */}
          <input
            type="email"
            className="p-2 rounded-md  w-full border border-gray-200 "
            placeholder="Email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            minLength={2}
            maxLength={100}
          />
        </div>
        <div className="flex flex-col ">
          <label
            className={`  ${!validPhone ? " text-red-500 " : "text-gray-400"} `}
          >
            Phone Number {!validPhone && "(Please enter a valid phone)"}
          </label>
          <Phone
            setValidPhone={setValidPhone}
            setPhone={setPhone}
            phone={phone}
            validPhone={validPhone}
          />
        </div>
        {!clientSecret ? (
          <div className="  w-full flex    justify-center items-center  ">
            <div className=" flex justify-center items-center    h-1/2   w-5/6">
              <div
                role="status"
                className="flex justify-center items-center h-[50px] "
              >
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-700"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          //@ts-ignore
          <>
            {/* @ts-ignore */}
            <div className="flex flex-col gap-1">
              <h2 className="text-xl font-semibold">Payment details</h2>
              <p className="text-sm">
                Pay securely-we use SSL encryption to keep your data safe
              </p>
            </div>
            <PaymentElement id="payment-element" />
          </>
        )}
        <div className="w-full  flex flex-col">
          <button
            disabled={paymentLoading}
            className="py-3 w-full disabled:bg-gray-200  bg-[#F15C5A] rounded-md text-white text-xl "
            id="ga4-event-listener-checkout"
            type="submit"
          >
            {paymentLoading ? (
              <span className="animate-pulse">Processing</span>
            ) : (
              "Pay"
            )}
          </button>
          {paymentLoading && (
            <span className="text-red-500">
              Please do not navigate away from this page or close your browser
              while your payment is being processed. Doing so may result in an
              incomplete or failed transaction. Please wait until the payment
              processing is complete and you are redirected to a confirmation
              page. Thank you for your patience and understanding.
            </span>
          )}
        </div>
        <div className=" md:hidden flex flex-col gap-3 mt-4 slide-in ">
          <h3 className="font-semibold text-xl ">Book With Confidence</h3>
          <div className="flex gap-2 mt-1 ">
            <img
              src="https://res.cloudinary.com/see-sight-tours/image/upload/v1691608033/icons-website/Untitled-1_Lowest_Price_Guarantee_up8kxd.svg"
              width={25}
              height={15}
              alt="lowest price"
            />
            <span className="text-base font-semibold ">
              Lowest Price Guarantee
            </span>
          </div>
          <div className="flex items-center gap-2 mt-1  ">
            <img
              src="https://res.cloudinary.com/see-sight-tours/image/upload/v1691608033/icons-website/Untitled-1_Fee_Cancellation_Up_to_24hrs_in_advance_gr18kl.svg"
              width={25}
              height={15}
              alt="lowest price"
            />
            <span className="text-base font-semibold ">
              Free Cancellation Up to 24hrs in advance
            </span>
          </div>
          <div className="flex items-center gap-2 mt-1  ">
            <img
              src="https://res.cloudinary.com/see-sight-tours/image/upload/v1691608033/icons-website/Untitled-1_Book_Securely_Pay_securely_lwow07.svg"
              width={25}
              height={40}
              alt="lowest price"
            />
            <span className="text-base font-semibold ">
              Book Securely Pay securely
            </span>
          </div>
          <div className="flex flex-col align-middle   ">
            <span className="text-xl font-semibold ">Call to book</span>

            <a
              href="tel:1-888-961-6584"
              className="text-lg gap-2 text-blue-400 flex "
            >
              <img
                src="/phonelogo.png"
                width={20}
                height={10}
                alt="phone logo"
              />
              <span>1-888-961-6584</span>
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SinglePageCheckoutForm;
