import React, { useContext, useEffect, useState } from "react";

import Calendar from "react-calendar";
import styled from "styled-components";
import dayjs from "dayjs";
// import Image from "next/image";
import "react-calendar/dist/Calendar.css";
import {
  fetchProductAvailabilities,
  updateProductAvailabitoes,
} from "../../utils/getProductAvailabilites";
// import { useRouter } from "next/router";
import { PaxContext } from "../../utils/context/checkoutContext";
import { useQuery } from "@apollo/client";
import { FETCH_PRODUCT_BOAT } from "../../api/productPage";
import removeDublicates from "../../utils/removeDublicateTimeSlots";
import colors from "../../utils/constants";

const StyledCelendar = styled(Calendar)`
  position: absolute;
  z-index: 100;
  width: 100%;

  @media (max-width: 945px) {
    max-width: 100%;
  }
  .react-calendar__month-view__days {
    min-height: 300px;
    height: ${(props) => (props.modal ? "295px" : "265px")};
    width: 100%;
    @media (max-width: 1800px) {
      height: 295px;
    }
    @media (max-width: 945px) {
      height: ${(props) => (props.modal ? "285px" : "200px")};
    }
  }

  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation {
    margin-bottom: 0;
    height: 35px;
  }
  .react-calendar__tile {
    padding: 6px 6.6667px;
  }
  .react-calendar__navigation__label {
    pointer-events: none;
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }
`;

const DayContainer = styled.div`
  color: ${(props) => (props.active ? "white" : "black")};
  .react-calendar__month-view__days__day {
    background: gray;
  }
  .price {
    color: ${(props) => (props.active ? "white" : "#6b7280")};
    font-size: 12px;
  }

  display: flex;
  flex-direction: column;
`;
const TimeOptions = styled.div`
  display: flex;
  max-width: 48%;
  min-width: 48%;
  padding: 8px;
  justify-content: space-between;
`;
const PAXSELECTOR = styled.div`
  background: #ffffff;
  border-radius: 0px 15px 0px 0px;
  padding: 10px 47px;
`;
const RedRoundCheckbox = styled.div`
  position: relative;
  label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
    margin-top: 2px;
  }
  label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 12px;
  }
  input[type="checkbox"] {
    visibility: hidden;
  }

  input[type="checkbox"]:checked + label {
    background-color: #3b79c9;
    border-color: #3b79c9;
  }

  input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
`;

const DateAndPax = ({
  rezdyId,
  updateTourContext,
  mobile,
  privateTour,
  tourId,
  adult,
  child,
  infant,
  name,
  modal,
  detailsLoading,
}) => {
  const [mobileCalendar, setMobileCalendar] = useState(false);
  //   const router = useRouter();
  //@ts-expect-error
  const { updatePax, updateUser } = useContext(PaxContext);
  const { data } = useQuery(FETCH_PRODUCT_BOAT, {
    variables: {
      id: tourId,
    },
  });
  const [loading, setLoading] = useState(true);

  const [reloading, setReloading] = useState(false);
  const [noAvailability, setNoAvailability] = useState(false);
  const [productAvailabities, setProductAvailabilites] = useState([
    {
      id: 1,
      startTime: "2023-04-12",
      endTime: "2023-04-12",
      allDay: false,
      seats: 10,
      seatsAvailable: 150,
      prices: [
        {
          count: 4,
          label: "Adults",
          title: "children",
          price: `${adult}`,
        },
      ],
    },
  ]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs(productAvailabities[0].startTime).format("YYYY-MM-DD")
  );

  const [isOpen, setIsOpen] = useState(false);

  const [availabilityArray, setAvailabilityArray] = useState(() =>
    productAvailabities.map((item) =>
      dayjs(item.startTime).format("YYYY-MM-DD")
    )
  );

  const [selectedAvailaibility, setSelectedAvailability] = useState(() =>
    productAvailabities.filter(
      (date) => dayjs(date.startTime).format("YYYY-MM-DD") === selectedDate
    )
  );

  const [selectedTimeSlot, setSelectedTimeSlot] = useState({
    ...selectedAvailaibility[0],
    prices: [
      //@ts-ignore
      selectedAvailaibility[0].prices.sort((a, b) =>
        a.label.localeCompare(b.label)
      ),
    ],
  });

  const [passengerPax, setPassengerPax] = useState(() => {
    return {
      adults: {
        count: privateTour ? 1 : selectedTimeSlot.seatsAvailable >= 2 ? 2 : 1,
        price: `${privateTour ? selectedTimeSlot.prices[0].price : adult}`,
        title: selectedTimeSlot.prices[0].label,
      },
      children:
        selectedTimeSlot.prices[1]?.price !== null
          ? {
              count: 0,
              label: selectedTimeSlot.prices[1]?.label,
              price: `${child}`,
              title: selectedTimeSlot.prices[1]?.label,
            }
          : undefined,
      infants: selectedTimeSlot.prices[2]
        ? {
            count: 0,
            label: selectedTimeSlot.prices[2].label,
            price: `${infant}`,
            title: selectedTimeSlot.prices[2].label,
          }
        : undefined,
      seats: selectedTimeSlot.seats,
    };
  });
  const [totalPrice, setTotalPrice] = useState(() =>
    Number(passengerPax?.adults.price)
  );
  const [seatsFull, setSeatsFull] = useState(false);

  useEffect(() => {
    const getDate = async () => {
      const availability = await fetchProductAvailabilities({
        rezdyId,
        startDate: dayjs().format("YYYY-MM-DD"),
      });
      if (
        !availability ||
        (availability.length > 0 && availability[0].prices.length === 0)
      ) {
        setNoAvailability(true);
        setLoading(false);
        return;
      }
      if (availability) {
        setLoading(false);
      }
      setNoAvailability(false);
      setSelectedDate(dayjs(availability[0].startTime).format("YYYY-MM-DD"));
      setProductAvailabilites(availability);
      setAvailabilityArray(() => {
        return availability.map((item) =>
          dayjs(item.startTime).format("YYYY-MM-DD")
        );
      });
    };
    getDate();
  }, [rezdyId]);
  const updateAvailabity = async (startTime) => {
    //Check if we already loaded the availability
    const exist = productAvailabities.some(
      (a) =>
        dayjs(a.startTime).format("YYYY-MM-DD") ===
        dayjs(startTime).add(1, "days").format("YYYY-MM-DD")
    );
    if (exist) return;
    setReloading(true);
    const availability = await updateProductAvailabitoes({
      rezdyId,
      startDate: startTime,
    });

    if (!availability) {
      setReloading(false);
      return;
    }
    if (availability) {
      setReloading(false);
    }
    const uniques = removeDublicates([...productAvailabities, ...availability]);
    setProductAvailabilites(uniques);
  };
  useEffect(() => {
    setAvailabilityArray(() => {
      return productAvailabities.map((item) =>
        dayjs(item.startTime).format("YYYY-MM-DD")
      );
    });
  }, [productAvailabities]);

  useEffect(() => {
    setSelectedAvailability(() =>
      productAvailabities.filter(
        (date) => dayjs(date.startTime).format("YYYY-MM-DD") === selectedDate
      )
    );
  }, [selectedDate, productAvailabities]);

  useEffect(() => {
    if (selectedAvailaibility[0])
      setSelectedTimeSlot({
        ...selectedAvailaibility[0],
        prices:
          //@ts-ignore
          selectedAvailaibility[0].prices.sort((a, b) =>
            a.label.localeCompare(b.label)
          ),
      });
  }, [selectedAvailaibility]);
  useEffect(() => {
    const adults =
      passengerPax.adults.count * Number(passengerPax.adults.price);
    const children = passengerPax.children
      ? passengerPax.children.count * Number(passengerPax.children.price)
      : 0;
    const infants = passengerPax.infants
      ? passengerPax.infants.count * Number(passengerPax.infants.price)
      : 0;

    if (privateTour) {
      setTotalPrice(Number(passengerPax.adults.price));
      return;
    }
    setTotalPrice(() => Number(adults + children + infants));
  }, [passengerPax]);

  useEffect(() => {
    const childrens = passengerPax.children?.count || 0;

    const infants = passengerPax.infants?.count || 0;

    const totalPax = passengerPax.adults.count + childrens + infants;
    if (data && privateTour && totalPax >= data?.product?.quantityMax) {
      setSeatsFull(true);
      return;
    }

    if (totalPax >= passengerPax?.seats) {
      setSeatsFull(true);
      return;
    }
    setSeatsFull(false);
  }, [passengerPax]);
  useEffect(() => {
    selectedTimeSlot &&
      setPassengerPax({
        adults: {
          count: privateTour ? 1 : selectedTimeSlot.seatsAvailable > 1 ? 2 : 1,
          label: selectedTimeSlot.prices[0].label,
          price: `${privateTour ? selectedTimeSlot.prices[0].price : adult}`,
          title: selectedTimeSlot.prices[0].label,
        },
        children:
          selectedTimeSlot.prices[1]?.label === "Child"
            ? {
                count: 0,
                label: "Child",
                price: `${child === null ? 0 : child}`,
                title: selectedTimeSlot.prices[1].label,
              }
            : selectedTimeSlot.prices[1]?.label === "Infant"
            ? {
                count: 0,
                label: "Child",
                price: `${child === null ? 0 : child}`,
                title: selectedTimeSlot.prices[2].label,
              }
            : undefined,
        infants:
          selectedTimeSlot.prices[2]?.label === "Infant"
            ? {
                count: 0,
                label: "Infant",
                price: `${infant === null ? 0 : infant}`,
                title: selectedTimeSlot.prices[2].label,
              }
            : selectedTimeSlot.prices[2]?.label === "Child"
            ? {
                count: 0,
                label: "Infant",
                price: `${infant === null ? 0 : infant}`,
                title: selectedTimeSlot.prices[1].label,
              }
            : undefined,
        seats: selectedTimeSlot.seatsAvailable,
      });
  }, [selectedTimeSlot, productAvailabities]);
  const priceCheck = (date) => {
    const price = productAvailabities.filter(
      (item) =>
        dayjs(item.startTime).format("YYYY-MM-DD") ===
        dayjs(date).format("YYYY-MM-DD")
    )[0]?.prices;

    if (price) {
      return `$${
        price.filter((item) =>
          privateTour ? item.price : item.label === "Adult"
        )[0].price
      }`;
    }
    return "";
  };

  const proceedToCheckout = () => {
    // console.log("zzzzzzzzzzzzzzzzzz", {
    //   ...passengerPax,
    //   totalPrice,
    //   selectedTimeSlot,
    //   link: data?.product?.citiesProducts[0]?.city?.gmb_link?.city?.split(
    //     "/review"
    //   )[0],
    // });
    updatePax({
      ...passengerPax,
      totalPrice,
      selectedTimeSlot,
      link: data?.product?.citiesProducts[0]?.city?.gmb_link?.city?.split(
        "/review"
      )[0],
    });
    updateUser({});

    updateTourContext();
    // router.push("/checkout");
  };
  return (
    <div
      className={`mmd:block h-full flex items-center relative z-50 my-20 mx-5`}
      // className={`${
      //     mobile ? "block" : "hidden"
      //   } mmd:block h-full flex items-center relative z-50 `}
      style={{ zIndex: 1000 }}
    >
      {loading || detailsLoading ? (
        <div className=" flex justify-center items-center w-full h-full flex-col">
          <div role="status">
            <svg
              aria-hidden="true"
              className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
          <span className=" text-gray-500 mt-[20%] text-base ">
            Loading tour availabilities.
          </span>
        </div>
      ) : noAvailability ? (
        <div className=" flex justify-center items-center w-full h-44 flex-col ">
          <p className="text-gray-400 ">Coming Soon</p>
        </div>
      ) : (
        <div className="flex w-full justify-between gap-3 flex-wrap flex-col  ">
          <p className=" text-xl  font-semibold ">
            {" "}
            {privateTour
              ? `Starting From  ${Number(passengerPax.adults.price).toFixed(
                  2
                )} USD`
              : ` Starting From  ${Number(
                  selectedTimeSlot.prices[0].price
                ).toFixed(2)} USD `}
          </p>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setMobileCalendar(!mobileCalendar);
            }}
            className="border rounded border-gray-300 w-full   px-4 py-2 gap-2 flex relative items-center hover:cursor-pointer"
          >
            <img src="/calendar.svg" width={10} height={10} alt="calendar" />
            <span className=" text-gray-800 ">{selectedDate}</span>
            <div
              className={`rotate-180 ${
                mobileCalendar && "rotate-0"
              } absolute  right-3 px-4 `}
            >
              <img
                src="/dropDown.png"
                width={10}
                height={10}
                alt="dropdown"
                className="hover:cursor-pointer"
              />
            </div>
          </div>
          <div className=" flex  justify-between flex-wrap w-full gap-2 ">
            {removeDublicates(selectedAvailaibility).map((item) => (
              <TimeOptions
                key={item.startTime}
                className="text-gray-800 border rounded border-gray-300 gap-2"
              >
                <RedRoundCheckbox>
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={item.id === selectedTimeSlot?.id}
                  />
                  <label
                    onClick={() => {
                      setSelectedTimeSlot({
                        ...item,
                        //@ts-ignore
                        prices: item.prices.sort((a, b) =>
                          a.label.localeCompare(b.label)
                        ),
                      });
                    }}
                    htmlFor="checkbox"
                  ></label>
                </RedRoundCheckbox>

                <span className=" text-center flex-1 ">
                  {dayjs(item.startTime).format("  h:mm a")}
                </span>
              </TimeOptions>
            ))}
          </div>
          {reloading && (
            <div
              className=" flex justify-center items-center w-full flex-col absolute top-[70%]  "
              style={{ zIndex: 200 }}
            >
              <div role="status z-50 ">
                <svg
                  aria-hidden="true"
                  className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#3B79C9]  "
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            </div>
          )}
          {mobileCalendar && (
            <div className="relative">
              <StyledCelendar
                // @ts-ignore
                modal={modal}
                tileContent={({ activeStartDate, date, view }) => (
                  <DayContainer
                    active={
                      selectedDate === dayjs(date).format("YYYY-MM-DD")
                        ? true
                        : false
                    }
                  >
                    <p className=" text-sm  text-blue-400 price  ">
                      {priceCheck(date)}
                    </p>
                  </DayContainer>
                )}
                minDate={new Date()}
                showNeighboringMonth={false}
                onClickDay={(value) => {
                  setSelectedDate(dayjs(value).format("YYYY-MM-DD"));
                  setMobileCalendar(false);
                }}
                onActiveStartDateChange={({ action, activeStartDate }) => {
                  if (action === "next") updateAvailabity(activeStartDate);
                }}
                tileDisabled={({ activeStartDate, date, view }) => {
                  if (
                    availabilityArray
                      .slice(0, availabilityArray.length - 1)
                      .includes(dayjs(date).format("YYYY-MM-DD"))
                  ) {
                    return false;
                  }
                  return true;
                }}
                value={new Date(selectedDate)}
              />
            </div>
          )}
          <div className="flex flex-col sm:w-full  w-full">
            {seatsFull && !privateTour && (
              <p className=" text-[#3B79C9] text-base text-center">
                {" "}
                Maximum limit of seats reached{" "}
              </p>
            )}
            <div className="relative parent-container">
              <div
                onClick={() => setIsOpen(!isOpen)}
                className="flex justify-between cursor-pointer bg-white  pb-2 border border-gray-300 rounded-md py-2 pl-2 items-center align-middle text-black"
              >
                <span className="text-black">
                  {Object.values(passengerPax).map((item) => {
                    // @ts-ignore
                    return item?.count > 0
                      ? //@ts-ignore
                        `${item.label} ${privateTour ? "" : `${item.count}X`} `
                      : "";
                  })}
                </span>
                <button
                  className={`bg-white px-4 py-2 rounded-md rotate-180 text-left transition-transform transition-fast ${
                    isOpen && "rotate-0"
                  } `}
                >
                  <img
                    src="/dropDown.png"
                    width={10}
                    height={10}
                    alt="dropdown"
                  />
                </button>
              </div>

              <div
                className={` relative md:relative right-0 pb-2   bg-white   w-full ${
                  !isOpen ? "hidden" : ""
                }`}
              >
                {!privateTour ? (
                  <>
                    {" "}
                    <div className="grid grid-cols-4">
                      <div className="col-span-2 flex flex-col  px-2   ">
                        <div className="flex items-center ">
                          <span className="text-black">
                            {passengerPax.adults.label}
                          </span>
                          <span className="text-black    text-[10px] ">
                            {passengerPax.adults.label === "Quantity"
                              ? "(All ages)"
                              : "(Ages 13+)"}
                          </span>
                        </div>
                        <div className=" flex justify-start ">
                          <div className="flex items-center ">
                            <span className=" text-[#3B79C9]  text-sm ">
                              ${passengerPax.adults.price}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2 grid grid-cols-3">
                        <div className="flex justify-center items-center  ">
                          <button
                            onClick={() => {
                              if (passengerPax.adults.count <= 1) return;
                              setPassengerPax({
                                ...passengerPax,
                                adults: {
                                  ...passengerPax.adults,
                                  count: passengerPax.adults.count - 1,
                                },
                              });
                            }}
                            className="col-span-1 rounded-full w-8 h-8 bg-white border-2  text-black"
                          >
                            -
                          </button>
                        </div>
                        <div className="flex justify-center items-center ">
                          <span className="col-span-1 text-center text-black">
                            {passengerPax.adults.count}
                          </span>
                        </div>{" "}
                        <div className="flex justify-center items-center ">
                          <button
                            onClick={() => {
                              if (!seatsFull)
                                setPassengerPax({
                                  ...passengerPax,
                                  adults: {
                                    ...passengerPax.adults,
                                    count: passengerPax.adults.count + 1,
                                  },
                                });
                            }}
                            className="col-span-1 rounded-full   w-8 h-8 bg-white border-2  text-black"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    {passengerPax.children &&
                      Number(passengerPax.children.price) !== 0 && (
                        <div className="grid grid-cols-4">
                          <div className="col-span-2 flex flex-col   px-2 ">
                            <div className="flex items-center">
                              <span className="text-black">
                                {passengerPax.children.label}
                              </span>
                              <span className="text-black    text-[10px] ">
                                {passengerPax.children.label === "Child" &&
                                  "(Ages 5-12)"}
                                {passengerPax.children.label === "Infant" &&
                                  "(Ages 0-5)"}
                              </span>
                            </div>
                            <div className="col-span-1 flex justify-start ">
                              <div className="flex items-center text-gray-500 ">
                                <span
                                  className={`text-[${colors?.primary}]  text-sm`}
                                >
                                  ${passengerPax.children.price}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-2 grid grid-cols-3">
                            <div className="flex justify-center items-center ">
                              <button
                                onClick={() => {
                                  if (
                                    passengerPax.children &&
                                    passengerPax.children.count > 0
                                  ) {
                                    setPassengerPax({
                                      ...passengerPax,
                                      children: {
                                        ...passengerPax.children,
                                        count: passengerPax.children.count - 1,
                                      },
                                    });
                                  }
                                }}
                                className="col-span-1 rounded-full    w-8 h-8 bg-white border-2  text-black"
                              >
                                -
                              </button>
                            </div>
                            <div className="flex justify-center items-center ">
                              <span className="col-span-1 text-center text-black">
                                {passengerPax.children.count}
                              </span>
                            </div>{" "}
                            <div
                              onClick={() => {
                                if (passengerPax.children) {
                                  if (!seatsFull)
                                    setPassengerPax({
                                      ...passengerPax,
                                      children: {
                                        ...passengerPax.children,
                                        count: passengerPax.children.count + 1,
                                      },
                                    });
                                }
                              }}
                              className="flex justify-center items-center "
                            >
                              <button className="col-span-1 rounded-full   w-8 h-8 bg-white border-2  text-black">
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    {passengerPax.infants &&
                      Number(passengerPax.infants.price) !== 0 && (
                        <div className="grid grid-cols-4">
                          <div className="col-span-2 flex flex-col  px-2 ">
                            <div className="flex items-center">
                              <span className="text-black">
                                {passengerPax.infants.label}
                              </span>
                              <span className="text-black    text-[10px] ">
                                {passengerPax.infants.label === "Child" &&
                                  "(Ages 5-12)"}
                                {passengerPax.infants.label === "Infant" &&
                                  "(Ages 0-5)"}
                              </span>
                            </div>
                            <div className="col-span-1 flex justify-start ">
                              <div className="flex items-center ">
                                <span className=" text-[#3B79C9]  text-sm   ">
                                  ${passengerPax.infants.price}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-span-2 grid grid-cols-3">
                            <div className="flex justify-center items-center ">
                              <button
                                onClick={() => {
                                  if (
                                    passengerPax.infants &&
                                    passengerPax.infants.count > 0
                                  ) {
                                    setPassengerPax({
                                      ...passengerPax,
                                      infants: {
                                        ...passengerPax.infants,
                                        count: passengerPax.infants.count - 1,
                                      },
                                    });
                                  }
                                }}
                                className="col-span-1 rounded-full    w-8 h-8 bg-white border-2  text-black"
                              >
                                -
                              </button>
                            </div>
                            <div className="flex justify-center items-center ">
                              <span className="col-span-1 text-center text-black">
                                {passengerPax.infants.count}
                              </span>
                            </div>{" "}
                            <div className="flex justify-center items-center ">
                              <button
                                onClick={() => {
                                  if (!seatsFull)
                                    if (passengerPax.infants) {
                                      setPassengerPax({
                                        ...passengerPax,
                                        infants: {
                                          ...passengerPax.infants,
                                          count: passengerPax.infants.count + 1,
                                        },
                                      });
                                    }
                                }}
                                className="col-span-1 rounded-full   w-8 h-8 bg-white border-2  text-black"
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                ) : (
                  <div>
                    {selectedTimeSlot.prices.map((item) => {
                      if (item.label !== undefined) {
                        return (
                          <p
                            className="text-black py-2 border-[1px] border-gray-100 px-4  cursor-pointer hover:bg-gray-100  "
                            key={item.label}
                            onClick={() => {
                              setPassengerPax({
                                ...passengerPax,
                                adults: {
                                  count: Number(item.label[0]),
                                  label: item.label,
                                  price: item.price,
                                  title: item.label,
                                },
                              });
                              setIsOpen(false);
                            }}
                          >
                            {item.label} :{" "}
                            <span className=" font-semibold ">
                              ${Number(item.price).toFixed(2)}
                            </span>
                          </p>
                        );
                      }
                    })}
                  </div>
                )}
              </div>
            </div>
            <p className="pl-2 text-xs">Prices shown in USD</p>
          </div>

          <button
            className={`bg-[#3B79C9] rounded-md py-3 text-white`}
            onClick={() => proceedToCheckout()}
          >
            Book now $
            {privateTour ? passengerPax.adults.price : totalPrice.toFixed(2)}
          </button>
          <div className="flex items-start gap-2 slide-in   ">
            <img
              src="/tickmark_icon.png"
              className=" mt-2 "
              width={15}
              height={7}
              alt="tick mark"
            />
            <span className="font-semibold">Lowest price guarantee </span>
          </div>
          <div className="flex items-start gap-2 slide-in ">
            <img
              src="/tickmark_icon.png"
              className=" mt-2 "
              width={15}
              height={7}
              alt="tick mark"
            />
            <span className="font-semibold">
              Free Cancellation Up to 24hrs in advance{" "}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateAndPax;
