import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/a11y";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-fade";
import SwiperCore, { Navigation } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";

SwiperCore.use([Navigation]);

// interface IMAGEARRAY {
//   photos: any;
//   title: string;
// }
const PageHeroSliderSkeleton = () => {
  return (
    <div className="flex mt-2">
      <div className="flex w-full justify-between">
        <div className="xxsm:w-full md:w-[82%]">
          <div className="animate-pulse bg-gray-200 w-full object-cover xxsm:h-[360px] md:h-[410px] lg:h-[494px] rounded-[5px] relative">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="flex items-center justify-center w-24 h-24 bg-gray-300 rounded-full">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div className="xxsm:hidden w-[18%] xxsm:h-[360px] md:h-[410px] lg:h-[494px] overflow-y-scroll no-scrollbar md:flex flex-col justify-start items-start pl-2">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className="image-item animate-pulse bg-gray-200 w-full h-[75px] rounded-[5px] mb-2 relative"
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="flex items-center justify-center w-10 h-10 bg-gray-300 rounded-full">
                  <svg
                    className="w-6 h-6 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 18"
                  >
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const PageHeroSlider = ({ photos, title }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [isFirstSlide, setIsFirstSlide] = useState(false);

  const handleSlideChange = (swiper) => {
    setSelectedImageIndex(swiper.activeIndex);

    setIsLastSlide(swiper.isEnd);
    setIsFirstSlide(swiper.isBeginning);
  };
  const handleSwiperInit = (swiper) => {
    setIsFirstSlide(swiper.isBeginning);
  };
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    swiper?.slideTo(index);
  };
  useEffect(() => {
    const element = document.getElementById("selected-image");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedImageIndex]);

  if (!photos || photos.length === 0) {
    return <PageHeroSliderSkeleton />;
  } else {
    return (
      <div className="flex mt-2">
        <div className="flex w-full justify-between">
          <div className="xxsm:w-full md:w-[82%]">
            <Swiper
              onSwiper={setSwiper}
              spaceBetween={20}
              slidesPerView={1}
              effect="slide"
              grabCursor
              onSlideChange={(swiper) => handleSlideChange(swiper)}
              onInit={(swiper) => handleSwiperInit(swiper)}
              navigation={{
                prevEl: ".custom-button-left",
                nextEl: ".custom-button-right",
              }}
              a11y={{ enabled: true }}
              observer
              observeParents
              observeSlideChildren
            >
              {Array.isArray(photos) &&
                photos.length > 0 &&
                photos.map((photo) => {
                  return (
                    <SwiperSlide key={photo} className="min-h-full">
                      <div
                        className={`cursor-pointer w-[1000px] xxsm:min-h-[320px]  sm:min-h-[494px] `}
                      >
                        <img
                          src={photo}
                          alt={photo}
                          key={photo}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
              <>
                <div
                  style={{ left: "15px", top: "50%" }}
                  className="flex custom-button-left absolute z-20 cursor-pointer xxsm:pl-[5px] md:pl-[0px]"
                >
                  {isFirstSlide ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M30 16C30 13.2311 29.1789 10.5243 27.6406 8.22202C26.1022 5.91973 23.9157 4.12531 21.3576 3.06569C18.7994 2.00606 15.9845 1.72881 13.2687 2.26901C10.553 2.8092 8.05844 4.14257 6.10051 6.1005C4.14257 8.05844 2.8092 10.553 2.26901 13.2687C1.72881 15.9845 2.00606 18.7994 3.06569 21.3576C4.12532 23.9157 5.91973 26.1022 8.22202 27.6406C10.5243 29.1789 13.2311 30 16 30C19.713 30 23.274 28.525 25.8995 25.8995C28.525 23.274 30 19.713 30 16ZM24 17L11.85 17L17.43 22.607L16 24L8 16L16 8L17.43 9.427L11.85 15L24 15L24 17Z"
                        fill="#D7D5D5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M30 16C30 13.2311 29.1789 10.5243 27.6406 8.22202C26.1022 5.91973 23.9157 4.12531 21.3576 3.06569C18.7994 2.00606 15.9845 1.72881 13.2687 2.26901C10.553 2.8092 8.05844 4.14257 6.10051 6.1005C4.14257 8.05844 2.8092 10.553 2.26901 13.2687C1.72881 15.9845 2.00606 18.7994 3.06569 21.3576C4.12532 23.9157 5.91973 26.1022 8.22202 27.6406C10.5243 29.1789 13.2311 30 16 30C19.713 30 23.274 28.525 25.8995 25.8995C28.525 23.274 30 19.713 30 16ZM24 17L11.85 17L17.43 22.607L16 24L8 16L16 8L17.43 9.427L11.85 15L24 15L24 17Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </div>
                <div
                  style={{ right: "0px", top: "50%" }}
                  className="flex custom-button-right absolute z-20 cursor-pointer pr-[20px]"
                >
                  {isLastSlide ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M2 16C2 18.7689 2.82109 21.4757 4.35943 23.778C5.89777 26.0803 8.08427 27.8747 10.6424 28.9343C13.2006 29.9939 16.0155 30.2712 18.7313 29.731C21.447 29.1908 23.9416 27.8574 25.8995 25.8995C27.8574 23.9416 29.1908 21.447 29.731 18.7313C30.2712 16.0155 29.9939 13.2006 28.9343 10.6424C27.8747 8.08427 26.0803 5.89777 23.778 4.35943C21.4757 2.82109 18.7689 2 16 2C12.287 2 8.72601 3.475 6.1005 6.1005C3.475 8.72601 2 12.287 2 16ZM8 15H20.15L14.57 9.393L16 8L24 16L16 24L14.57 22.573L20.15 17H8V15Z"
                        fill="#D7D5D5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M2 16C2 18.7689 2.82109 21.4757 4.35943 23.778C5.89777 26.0803 8.08427 27.8747 10.6424 28.9343C13.2006 29.9939 16.0155 30.2712 18.7313 29.731C21.447 29.1908 23.9416 27.8574 25.8995 25.8995C27.8574 23.9416 29.1908 21.447 29.731 18.7313C30.2712 16.0155 29.9939 13.2006 28.9343 10.6424C27.8747 8.08427 26.0803 5.89777 23.778 4.35943C21.4757 2.82109 18.7689 2 16 2C12.287 2 8.72601 3.475 6.1005 6.1005C3.475 8.72601 2 12.287 2 16ZM8 15H20.15L14.57 9.393L16 8L24 16L16 24L14.57 22.573L20.15 17H8V15Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </div>
              </>
            </Swiper>
          </div>

          <div className="xxsm:hidden w-[18%] xxsm:h-[360px] md:h-[410px] lg:h-[494px] overflow-y-scroll no-scrollbar md:flex flex-col justify-start items-start pl-2">
            {photos?.map((photo, index) => {
              return (
                <div
                  id={selectedImageIndex === index ? "selected-image" : ""}
                  key={photo}
                  className={`image-item cursor-pointer ${
                    selectedImageIndex === index ? "highlight" : ""
                  }`}
                  onClick={() => handleImageClick(index)}
                >
                  <img
                    src={photo}
                    alt={photo}
                    key={photo}
                    className="w-full object-cover h-[80px] rounded-[5px]"
                  />
                </div>
              );
            })}
          </div>
        </div>
        <style jsx>{`
          .highlight {
            border: 4px solid #2191fa;
            border-radius: 9px;
          }

          .image-item {
            width: 100%;
            height: 87px;
            margin-bottom: 7px;
          }
        `}</style>
      </div>
    );
  }
};

export default PageHeroSlider;
