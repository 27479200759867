function removeDuplicates(arr) {
  const uniqueIds = {};
  return arr.filter(obj => {
    if (!uniqueIds[obj.id]) {
      uniqueIds[obj.id] = true;
      return true;
    }
    return false;
  });
}
export default removeDuplicates;
