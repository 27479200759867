export const colors = {
  primary: "#3B79C9",
  secondary: "",
  success: "",
  danger: "",
  warning: "",
  info: "",
  light: "",
  dark: "",
  white: "",
  black: "",
  gray: "",
  grayDark: "",
  grayLight: "",
  grayLightest: "",
  grayLighter: "",
};
export default colors;
