import React from "react";
import { useFormik } from "formik";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./form.css";
import { Typography } from "@mui/material";

const Carrer = () => {
  const formik = useFormik({
    initialValues: {
      applicationFor: "",
      firstName: "",
      familyName: "",
      citizenship: "",
      dateOfBirth: "",
      address: "",
      zipCode: "",
      city: "",
      phone: "",
      email: "",
      identityDocument: null,
      drivingLicenseDocument: null,
      dataProcessingConfirmed: false,
    },
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log(values);
    },
  });

  return (
    <>
      <Header />
      <div
        className="container"
        style={{ marginTop: "20vh", marginBottom: "20vh" }}
      >
        <center>
          <Typography variant="h2" component="h2">
            Might be a good fit for us?
          </Typography>
          <Typography variant="body1" component="p">
            Please fill out the form below, we'll get back to you as soon as
            possible.
          </Typography>
        </center>

        <fieldset>
          <Typography variant="h5" component="h5" className="my-5">
            Job Description
          </Typography>
          <br />
          <Typography>
            As a Tour Guide, you will be responsible for leading and guiding
            visitors on walking or driving tours of various locations. You will
            provide interesting and informative commentary about the history,
            culture, and attractions of the area. Your main goal is to ensure
            that visitors have a memorable and enjoyable experience.
          </Typography>
          <br />
        </fieldset>

        <fieldset>
          <Typography variant="h5" component="h5">
            Responsibilities
          </Typography>
          <br />
          <ul style={{ listStyleType: "disc" }}>
            <li>
              <Typography variant="p" component="p">
                - Lead and guide visitors on walking or driving tours
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Provide interesting and informative commentary about the area
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Ensure the safety and well-being of the tour group
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Answer questions and provide assistance to visitors
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Maintain a professional and friendly demeanor
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Handle unexpected situations or emergencies calmly and
                efficiently
              </Typography>
            </li>
          </ul>
        </fieldset>

        <fieldset>
          <br />
          <Typography variant="h5" component="h5">
            Benefits
          </Typography>
          <br />
          <ul>
            <li>
              <Typography variant="p" component="p">
                - Flexible work schedule
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Opportunity to meet and interact with people from all over the
                world
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Learn about the history and culture of the area
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Enhance your public speaking and communication skills
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Competitive salary and potential for tips
              </Typography>
            </li>
          </ul>
        </fieldset>

        <fieldset>
          <br />
          <Typography variant="h5" component="h5">
            Requirements
          </Typography>
          <br />
          <ul>
            <li>
              <Typography variant="p" component="p">
                - Excellent knowledge of the area's history, culture, and
                attractions
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Strong communication and interpersonal skills
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Ability to engage and captivate an audience
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Good physical fitness and stamina
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Ability to handle unexpected situations or emergencies
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Valid driver's license (for driving tour guides)
              </Typography>
            </li>
            <li>
              <Typography variant="p" component="p">
                - Fluency in multiple languages (preferred, but not required)
              </Typography>
            </li>
          </ul>
          <br />
          <br />
          <br />
          <br />
        </fieldset>

        <form
          onSubmit={formik.handleSubmit}
          id="ft-form"
          acceptCharset="UTF-8"
          encType="multipart/form-data"
        >
          <fieldset>
            <legend>Application</legend>
            <label>
              Application for *
              <select
                name="applicationFor"
                value={formik.values.applicationFor}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              >
                <option value="">Select an option</option>
                {/* <option value="guest-services">Guest Services</option> */}
                <option value="walking-tour-guide">Walking Tour Guide</option>
                <option value="driving-tour-guide">Driving Tour Guide</option>
                {/* <option value="operations">Operations</option> */}
                {/* <option value="fullstack-developer">Fullstack Developer</option> */}
              </select>
            </label>
          </fieldset>
          <fieldset>
            <legend>Personal data</legend>
            <div className="two-cols">
              <label>
                First name *
                <input
                  type="text"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              </label>
              <label>
                Family name *
                <input
                  type="text"
                  name="familyName"
                  value={formik.values.familyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              </label>
            </div>
            <div className="two-cols">
              <label>
                Citizenship
                <input
                  type="text"
                  name="citizenship"
                  value={formik.values.citizenship}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </label>
              <label>
                Date of birth
                <input
                  type="date"
                  name="dateOfBirth"
                  value={formik.values.dateOfBirth}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </label>
            </div>
            <label>
              Address
              <input
                type="text"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            <div className="two-cols">
              <label>
                ZIP Code
                <input
                  type="text"
                  name="zipCode"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </label>
              <label>
                City
                <select
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                >
                  <option value="">Select a city</option>
                  <option value="niagara-falls-canada">
                    Niagara Falls, Canada
                  </option>
                  <option value="niagara-falls-usa">Niagara Falls, USA</option>
                  <option value="halifax">Halifax</option>
                  <option value="toronto">Toronto</option>
                  <option value="prince-edward-island">
                    Prince Edward Island
                  </option>
                </select>
              </label>
            </div>
            <div className="two-cols">
              <label>
                Phone *
                <input
                  type="tel"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              </label>
              <label>
                Email address *
                <input
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
              </label>
            </div>
          </fieldset>
          <fieldset>
            <legend>Application documents</legend>
            <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
            <div className="two-cols">
              <label>
                Identity document
                <input
                  type="file"
                  name="identityDocument"
                  accept=".jpg,.jpeg,.png"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "identityDocument",
                      event.currentTarget.files[0]
                    );
                  }}
                />
              </label>
              <label>
                Driving license document
                <input
                  type="file"
                  name="drivingLicenseDocument"
                  accept=".jpg,.jpeg,.png"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "drivingLicenseDocument",
                      event.currentTarget.files[0]
                    );
                  }}
                />
              </label>
            </div>
            <p>
              Possible file types for Identity document: JPG, JPEG, PNG. Maximum
              file size: 10 MB.
            </p>
          </fieldset>
          <fieldset>
            <div>
              <label>
                <input
                  type="checkbox"
                  name="dataProcessingConfirmed"
                  value="Yes"
                  checked={formik.values.dataProcessingConfirmed}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                <span>
                  I agree to the processing of my data for the handling of my
                  request by{" "}
                  <a href="https://trippatron.com" target="_blank">
                    Tripatron
                  </a>
                  . *
                </span>
              </label>
            </div>
          </fieldset>
          <div className="btns">
            <input type="submit" value="Submit application" />
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Carrer;
