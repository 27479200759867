import { gql } from "@apollo/client";

const CITIES_PAGE = gql`
  query CITIES_PAGE {
    citiesPage {
      header
      subheader
      pageTitle: page_title
      metaDescription: meta_description
      heroMedia: banner_media {
        name
        alternativeText
        url
        fragment: caption
        type: provider_metadata
      }
      canonical
      featured: featured_cities {
        city {
          id
          name
          slug
          cardSnippet: card_snippet
          cardMedia: card_media {
            name
            alt: alternativeText
            url
            fragment: caption
            type: provider_metadata
          }
        }
      }
      cities: all_cities {
        city {
          id
          name
          slug
          cardSnippet: card_snippet
          cardMedia: card_media {
            name
            alt: alternativeText
            url
            fragment: caption
            type: provider_metadata
          }
        }
      }
    }
    boatnew_booking_aggregate {
      aggregate {
        count
      }
    }
    boatnew_customers_aggregate {
      aggregate {
        count
      }
    }
    strapi_reviews {
      rating
    }
  }
`;

const CITIES = gql`
  query CITIES {
    cities: citiesPage {
      featured: featured_cities {
        city {
          id
          name
          slug
          cardSnippet: card_snippet
          cardMedia: card_media {
            name
            alt: alternativeText
            url
            fragment: caption
            type: provider_metadata
          }
        }
      }
    }
  }
`;

export { CITIES_PAGE, CITIES };
