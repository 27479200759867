import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";
import "./assets/css/style.css";
import Home from "./pages/page.js";
import Tours from "./pages/tours/page.js";
import Cities from "./pages/cities/page.js";
import Demo from "./pages/tours/demo/page.js";
import Checkout from "./pages/checkout/index.js";
import DayTours from "./pages/day-tours/index.js";
import PrivateTours from "./pages/private-tours/index.js";
import AboutUs from "./pages/about-us/index.js";
import Reviews from "./pages/reviews/index.js";
import ContactUs from "./pages/contact-us/index.js";
import CityTours from "./pages/cities/tours.js";
import { useEffect } from "react";
import Tour from "./pages/tour/index.js";
import Carrer from "./pages/careers/form.js";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => window.scrollTo(0, 0), [location]);
  return null;
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/tours">
          <Route index element={<Tours />} />
          <Route path="day-tours" element={<DayTours />} />
          {/* <Route path="private-tours" element={<PrivateTours />} /> */}
          <Route path=":slug" element={<CityTours />} />
        </Route>
        <Route path="/tour">
          <Route index element={<Tours />} />
          <Route path=":slug" element={<Tour />} />
        </Route>
        <Route path="/cities" element={<Cities />} />
        <Route path="/reviews" element={<Reviews />} />
        {/* <Route path="/about-us" element={<AboutUs />} /> */}
        <Route path="/carrer" element={<Carrer />} />
        <Route path="/checkout" element={<Checkout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
