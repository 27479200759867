import React from "react";
import Card from "../card";
import AttractionCard from "../card/attraction";
import CityCard from "../card/city";
import TourCard from "../card/tour";

function CardList({ cards, type }) {
  return (
    <ul className="popular-list">
      {cards.map((card, index) => (
        <li key={index}>
          {type === "City" && <CityCard {...card} />}
          {type === "Attraction" && <AttractionCard {...card} />}
          {type === "Tour" && <TourCard {...card} />}
          {!["City", "Attraction", "Tour"].includes(type) && <Card {...card} />}
        </li>
      ))}
    </ul>
  );
}

export default CardList;
