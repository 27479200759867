import React, { createContext, useState } from 'react';

export const PaxContext = createContext(null);

export const PaxProvider = ({ children }) => {
  const [pax, setPax] = useState({});
  const [tour, setTour] = useState({});
  const [user, setUser] = useState({});
  const [blogCity, setBlogCity] = useState(undefined);

  const updatePax = newObject1 => {
    setPax(newObject1);
  };

  const updateTour = newObject2 => {
    setTour(newObject2);
  };

  const updateUser = newObject3 => {
    setUser(newObject3);
  };
  const updateBlogCity = newObject4 => {
    setBlogCity(newObject4);
  };

  const contextValue = {
    pax,
    tour,
    user,
    blogCity,
    updatePax,
    updateTour,
    updateUser,
    updateBlogCity
  };

  return (
    <PaxContext.Provider value={contextValue}>{children}</PaxContext.Provider>
  );
};
