import Header from "../components/header";
import Footer from "../components/footer";
import CardList from "../components/cardList";
import cities from "../data/cities";

const cards = [
  {
    imageSrc: "../assets/images/popular-1.jpg",
    altText: "San miguel, italy",
    subtitle: "Italy",
    title: "San miguel",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: "../assets/images/popular-2.jpg",
    altText: "Burj khalifa, dubai",
    subtitle: "Dubai",
    title: "Burj khalifa",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
  {
    imageSrc: "../assets/images/popular-3.jpg",
    altText: "Kyoto temple, japan",
    subtitle: "Japan",
    title: "Kyoto temple",
    text: "Fusce hic augue velit wisi ips quibusdam pariatur, iusto.",
  },
];

export default function Home() {
  return (
    <>
      <Header variant="hero" />

      <main>
        <article>
          {/* HERO section */}
          <section class="hero" id="home">
            <div class="container">
              <h2 class="h1 hero-title">Journey to explore world</h2>

              <p class="hero-text">
                Experience the rich culture, diverse wildlife, and thrilling
                adventures that await you
              </p>

              {/* <div class="btn-group">
                <button class="btn btn-primary">Learn more</button>

                <button class="btn btn-secondary">Book now</button>
              </div> */}
            </div>
          </section>

          {/* TOUR SEARCH */}
          {/* <section class="tour-search">
            <div class="container">
              <form action="" class="tour-search-form">
                <div class="input-wrapper">
                  <label for="destination" class="input-label">
                    Search Destination*
                  </label>

                  <input
                    type="text"
                    name="destination"
                    id="destination"
                    required
                    placeholder="Enter Destination"
                    class="input-field"
                  />
                </div>

                <div class="input-wrapper">
                  <label for="people" class="input-label">
                    Pax Number*
                  </label>

                  <input
                    type="number"
                    name="people"
                    id="people"
                    required
                    placeholder="No.of People"
                    class="input-field"
                  />
                </div>

                <div class="input-wrapper">
                  <label for="checkin" class="input-label">
                    Checkin Date**
                  </label>

                  <input
                    type="date"
                    name="checkin"
                    id="checkin"
                    required
                    class="input-field"
                  />
                </div>

                <div class="input-wrapper">
                  <label for="checkout" class="input-label">
                    Checkout Date*
                  </label>

                  <input
                    type="date"
                    name="checkout"
                    id="checkout"
                    required
                    class="input-field"
                  />
                </div>

                <button type="submit" class="btn btn-secondary">
                  Inquire now
                </button>
              </form>
            </div>
          </section> */}

          {/* POPULAR */}
          <section class="popular" id="destination">
            <div class="container">
              {/* <p class="section-subtitle">Uncover place</p> */}

              <h2 class="h2 section-title">Popular destination</h2>
              <br />
              <br />

              {/* <p class="section-text">
                Explore our popular destinations and uncover amazing places to
                visit. Whether you're looking for a relaxing beach getaway, an
                adventurous hiking trip, or a cultural city tour.
              </p> */}

              <CardList
                cards={cities.slice(0, 3).map((city) => {
                  city = city?.city;
                  return {
                    slug: city?.slug,
                    imageSrc: city?.cardMedia?.[0]?.url,
                    altText: city?.cardMedia?.[0]?.alt,
                    subtitle: city?.name,
                    title: city?.name,
                    text: city?.cardSnippet,
                  };
                })}
                type={"City"}
              />

              <a href="/cities">
                <button class="btn btn-primary">More destintion</button>
              </a>
            </div>
          </section>

          {/* PACKAGE */}
          {/* <section class="package" id="package">
            <div class="container">
              <p class="section-subtitle">Popular Packeges</p>

              <h2 class="h2 section-title">Checkout Our Packeges</h2>

              <p class="section-text">
                Fusce hic augue velit wisi quibusdam pariatur, iusto primis, nec
                nemo, rutrum. Vestibulum cumque laudantium. Sit ornare mollitia
                tenetur, aptent.
              </p>

              <ul class="package-list">
                <li>
                  <div class="package-card">
                    <figure class="card-banner">
                      <img
                        src="../assets/images/packege-1.jpg"
                        alt="Experience The Great Holiday On Beach"
                        loading="lazy"
                      />
                    </figure>

                    <div class="card-content">
                      <h3 class="h3 card-title">
                        Experience The Great Holiday On Beach
                      </h3>

                      <p class="card-text">
                        Laoreet, voluptatum nihil dolor esse quaerat mattis
                        explicabo maiores, est aliquet porttitor! Eaque, cras,
                        aspernatur.
                      </p>

                      <ul class="card-meta-list">
                        <li class="card-meta-item">
                          <div class="meta-box">
                            <ion-icon name="time"></ion-icon>

                            <p class="text">7D/6N</p>
                          </div>
                        </li>

                        <li class="card-meta-item">
                          <div class="meta-box">
                            <ion-icon name="people"></ion-icon>

                            <p class="text">pax: 10</p>
                          </div>
                        </li>

                        <li class="card-meta-item">
                          <div class="meta-box">
                            <ion-icon name="location"></ion-icon>

                            <p class="text">Malaysia</p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="card-price">
                      <div class="wrapper">
                        <p class="reviews">(25 reviews)</p>

                        <div class="card-rating">
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                        </div>
                      </div>

                      <p class="price">
                        $750
                        <span>/ per person</span>
                      </p>

                      <button class="btn btn-secondary">Book Now</button>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="package-card">
                    <figure class="card-banner">
                      <img
                        src="../assets/images/packege-2.jpg"
                        alt="Summer Holiday To The Oxolotan River"
                        loading="lazy"
                      />
                    </figure>

                    <div class="card-content">
                      <h3 class="h3 card-title">
                        Summer Holiday To The Oxolotan River
                      </h3>

                      <p class="card-text">
                        Laoreet, voluptatum nihil dolor esse quaerat mattis
                        explicabo maiores, est aliquet porttitor! Eaque, cras,
                        aspernatur.
                      </p>

                      <ul class="card-meta-list">
                        <li class="card-meta-item">
                          <div class="meta-box">
                            <ion-icon name="time"></ion-icon>

                            <p class="text">7D/6N</p>
                          </div>
                        </li>

                        <li class="card-meta-item">
                          <div class="meta-box">
                            <ion-icon name="people"></ion-icon>

                            <p class="text">pax: 10</p>
                          </div>
                        </li>

                        <li class="card-meta-item">
                          <div class="meta-box">
                            <ion-icon name="location"></ion-icon>

                            <p class="text">Malaysia</p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="card-price">
                      <div class="wrapper">
                        <p class="reviews">(20 reviews)</p>

                        <div class="card-rating">
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                        </div>
                      </div>

                      <p class="price">
                        $520
                        <span>/ per person</span>
                      </p>

                      <button class="btn btn-secondary">Book Now</button>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="package-card">
                    <figure class="card-banner">
                      <img
                        src="../assets/images/packege-3.jpg"
                        alt="Santorini Island's Weekend Vacation"
                        loading="lazy"
                      />
                    </figure>

                    <div class="card-content">
                      <h3 class="h3 card-title">
                        Santorini Island's Weekend Vacation
                      </h3>

                      <p class="card-text">
                        Laoreet, voluptatum nihil dolor esse quaerat mattis
                        explicabo maiores, est aliquet porttitor! Eaque, cras,
                        aspernatur.
                      </p>

                      <ul class="card-meta-list">
                        <li class="card-meta-item">
                          <div class="meta-box">
                            <ion-icon name="time"></ion-icon>

                            <p class="text">7D/6N</p>
                          </div>
                        </li>

                        <li class="card-meta-item">
                          <div class="meta-box">
                            <ion-icon name="people"></ion-icon>

                            <p class="text">pax: 10</p>
                          </div>
                        </li>

                        <li class="card-meta-item">
                          <div class="meta-box">
                            <ion-icon name="location"></ion-icon>

                            <p class="text">Malaysia</p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="card-price">
                      <div class="wrapper">
                        <p class="reviews">(40 reviews)</p>

                        <div class="card-rating">
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                          <ion-icon name="star"></ion-icon>
                        </div>
                      </div>

                      <p class="price">
                        $660
                        <span>/ per person</span>
                      </p>

                      <button class="btn btn-secondary">Book Now</button>
                    </div>
                  </div>
                </li>
              </ul>

              <button class="btn btn-primary">View All Packages</button>
            </div>
          </section> */}

          {/* GALLERY */}
          <section class="gallery" id="gallery">
            <div class="container">
              {/* <p class="section-subtitle">Photo Gallery</p> */}

              <h2 class="h2 section-title">Photo's From Travellers</h2>
              <br />
              <br />

              {/* <p class="section-text">
                Fusce hic augue velit wisi quibusdam pariatur, iusto primis, nec
                nemo, rutrum. Vestibulum cumque laudantium. Sit ornare mollitia
                tenetur, aptent.
              </p> */}

              <ul class="gallery-list">
                <li class="gallery-item">
                  <figure class="gallery-image">
                    <img
                      src="https://www.tripshepherd.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fsee-sight-tours%2Fimage%2Fupload%2Fv1581435105%2Fnikola-tesla-statue-goat-island.jpg&w=640&q=70"
                      alt="Gallery image"
                    />
                  </figure>
                </li>

                <li class="gallery-item">
                  <figure class="gallery-image">
                    <img
                      src="https://www.tripshepherd.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fsee-sight-tours%2Fimage%2Fupload%2Fv1581439693%2Fskylon-tower.jpg&w=640&q=70"
                      alt="Gallery image"
                    />
                  </figure>
                </li>

                <li class="gallery-item">
                  <figure class="gallery-image">
                    <img
                      src="https://www.tripshepherd.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fsee-sight-tours%2Fimage%2Fupload%2Fv1668177831%2Fstrapi%2F4_Light_show_1aa4a1430b.jpg&w=640&q=70"
                      alt="Gallery image"
                    />
                  </figure>
                </li>

                <li class="gallery-item">
                  <figure class="gallery-image">
                    <img
                      src="https://www.tripshepherd.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fsee-sight-tours%2Fimage%2Fupload%2Fv1714134699%2Fstrapi%2FNiagara_Falls_Food_Walking_5_f66d0bca1a.jpg&w=640&q=70"
                      alt="Gallery image"
                    />
                  </figure>
                </li>

                <li class="gallery-item">
                  <figure class="gallery-image">
                    <img
                      src="https://www.tripshepherd.com/_next/image?url=https%3A%2F%2Fres.cloudinary.com%2Fsee-sight-tours%2Fimage%2Fupload%2Fv1714134699%2Fstrapi%2FNiagara_Falls_Food_Walking_2_78c6c81cef.jpg&w=640&q=70"
                      alt="Gallery image"
                    />
                  </figure>
                </li>
              </ul>
            </div>
          </section>

          {/* CTA */}
          <section class="cta" id="contact">
            <div class="container">
              <div class="cta-content">
                <p class="section-subtitle">Call To Action</p>

                <h2 class="h2 section-title">
                  Ready For Unforgatable Travel. Remember Us!
                </h2>

                {/* <p class="section-text">
                  Fusce hic augue velit wisi quibusdam pariatur, iusto primis,
                  nec nemo, rutrum. Vestibulum cumque laudantium. Sit ornare
                  mollitia tenetur, aptent.
                </p> */}
              </div>

              <button class="btn btn-secondary">Contact Us !</button>
            </div>
          </section>
        </article>
      </main>

      <Footer />
    </>
  );
}
